import { Component, Input, Output, EventEmitter } from '@angular/core';
import { InboundFax } from '../../../core/models/inbound-fax.model';
import { SimilarInboundFax } from 'app/core/models/similar-inbound-fax.model';

@Component({
  selector: 'app-inbound-fax-potential-duplicates',
  templateUrl: './inbound-fax-potential-duplicates.component.html',
  styleUrls: ['./inbound-fax-potential-duplicates.component.scss'],
})
export class InboundFaxPotentialDuplicatesComponent {
  @Input() inboundFax: InboundFax;
  @Input() selectedFax: SimilarInboundFax;
  @Input() similarInboundFaxes: SimilarInboundFax[];

  @Output() selectedFaxChanged = new EventEmitter<SimilarInboundFax>;

  onSimilarFaxClick(similarInboundFax: SimilarInboundFax) {
    this.setSelectedFax(similarInboundFax);
  }

  onSimilarFaxKeyPress(similarInboundFax: SimilarInboundFax, event: KeyboardEvent) {
    if (event.key === 'Enter' || event.key === ' ') {
      this.setSelectedFax(similarInboundFax);
    }
  }

  private setSelectedFax(similarInboundFax: SimilarInboundFax) {
    this.selectedFax = similarInboundFax;
    this.selectedFaxChanged.emit(similarInboundFax);
  }

}
