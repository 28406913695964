import { Component, OnInit, Input } from '@angular/core';
import { ClientProgramMetrics } from 'app/core/models/client-program-metrics.model';
import { Client } from 'app/core/models/client.model';
import { ClientsService } from 'app/core/services/clients.service';

@Component({
  selector: 'app-client-program-metrics',
  templateUrl: './client-program-metrics.component.html',
  styleUrls: ['./client-program-metrics.component.scss'],
})
export class ClientProgramMetricsComponent implements OnInit {
  @Input() client: Client;
  clientProgramMetrics: ClientProgramMetrics;
  loadingProgramMetrics: boolean = true;

  constructor(private clientService: ClientsService) {
  }

  ngOnInit() {
    this.init();
  }

  private init() {
    this.clientService.getProgramMetrics(this.client.id).subscribe(clientProgramMetrics => {
      this.clientProgramMetrics = clientProgramMetrics;
      this.loadingProgramMetrics = false;
    });
  }
}