import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Client } from '../models/client.model';
import { ClientStats } from '../models/client-stats.model';
import { MinimalPatientInteractionLocation } from '../models/minimal-patient-interaction-location.model';
import { ApiService } from './api.service';
import { PagedResults } from '../models/paged-results/paged-results.model';
import { LimitOffsetPagingMeta } from '../models/paged-results/limit-offset-paging.model';
import { ClientProgramMetrics } from '../models/client-program-metrics.model';
import { ClientNetSavingsTrendBySources } from '../models/client-net-savings-trend-by-sources.model';

export class PagedClients implements PagedResults {
  clients: Client[];
  meta: LimitOffsetPagingMeta;

  get records() {
    return this.clients;
  }
}

export class ClientListFilters {
  applicableToReferralClaims?: boolean;
  applicableToIccClaims?: boolean;
  ehrAccessOnly?: boolean;
  ids?: number[];
}

@Injectable()
export class ClientsService extends ApiService {
  constructor(private http: HttpClient) {
    super();
  }

  get(id: number): Observable<Client> {
    return this.http.get<Client>(`${environment.captureApi.url}/clients/${id}`);
  }

  getStats(id: number): Observable<ClientStats> {
    return this.http.get<ClientStats>(`${environment.captureApi.url}/clients/${id}/stats`);
  }

  getList(
    paging: { page: number; pageSize: number } = null,
    filters: ClientListFilters = null
  ): Observable<PagedClients> {
    const params = new URLSearchParams();

    if (paging) {
      params.append('page', paging.page.toString());
      params.append('per_page', paging.pageSize.toString());
    }

    if (filters && filters.applicableToReferralClaims) {
      params.append('applicable_to_referral_claims', 'true');
    }

    if (filters && filters.applicableToIccClaims) {
      params.append('applicable_to_icc_claims', 'true');
    }

    if (filters && filters.ehrAccessOnly) {
      params.append('ehr_access_only', 'true');
    }

    if (filters && filters.ids) {
      filters.ids.forEach((id) => {
        params.append('ids[]', id.toString(10));
      })
    }

    return this.http.get<PagedClients>(`${environment.captureApi.url}/clients?${params.toString()}`);
  }

  update(id, attributes: any): Observable<Client> {
    return this.http.patch<Client>(`${environment.captureApi.url}/clients/${id}`, {
      client: attributes,
    });
  }

  create(attributes: any): Observable<Client> {
    return this.http.post<Client>(`${environment.captureApi.url}/clients`, {
      client: attributes,
    });
  }

  uploadLogo(clientId, signedId): Observable<Client> {
    return this.http.post<Client>(`${environment.captureApi.url}/clients/${clientId}/upload_logo`, {
      logo: { signedId },
    });
  }

  uploadEspNdcsFile(clientId, signedId): Observable<Client> {
    return this.http.post<Client>(`${environment.captureApi.url}/clients/${clientId}/upload_esp_ndcs_file`, {
      espNdcsFile: { signedId },
    });
  }

  deleteEspNdcsFile(clientId): Observable<Client> {
    return this.http.delete<Client>(`${environment.captureApi.url}/clients/${clientId}/delete_esp_ndcs_file`)
  }

  deactivate(clientId: number): Observable<Client> {
    return this.http.patch<Client>(`${environment.captureApi.url}/clients/${clientId}/deactivate`, {});
  }

  activate(clientId: number): Observable<Client> {
    return this.http.patch<Client>(`${environment.captureApi.url}/clients/${clientId}/activate`, {});
  }

  sendTestDirectMessage(clientId: number): Observable<void> {
    return this.http.patch<void>(`${environment.captureApi.url}/clients/${clientId}/send_test_direct_message`, {});
  }

  getSpecialties(): Observable<string[]> {
    return this.http.get<string[]>(`${environment.captureApi.url}/clients/specialties`);
  }

  getEntityTypes(): Observable<string[]> {
    return this.http.get<string[]>(`${environment.captureApi.url}/clients/entity_types`);
  }

  getEncounterLocations(): Observable<MinimalPatientInteractionLocation[]> {
    return this.http.get<MinimalPatientInteractionLocation[]>(`${environment.captureApi.url}/clients/encounter_locations`);
  }

  viewing(): Observable<Client> {
    return this.http.get<Client>(`${environment.captureApi.url}/clients/viewing`);
  }

  getProgramMetrics(clientId: number): Observable<ClientProgramMetrics> {
    return this.http.get<ClientProgramMetrics>(`${environment.captureApi.url}/clients/${clientId}/program_metrics`);
  }

  getNetSavingsTrendBySource(clientId: number): Observable<ClientNetSavingsTrendBySources> {
    return this.http.get<ClientNetSavingsTrendBySources>(`${environment.captureApi.url}/clients/${clientId}/net_savings_trend_by_sources`);
  }
}
