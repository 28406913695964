import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { InboundFax } from '../models/inbound-fax.model';
import { ApiService } from './api.service';
import { InboundFaxStatus } from '../enums/inbound-fax-status.enum';
import { NextQueueItemProvider } from '../interfaces/next-queue-item-provider';
import { QueueProcessingService } from './queue-processing.service';
import { NextQueueItem } from '../models/next-queue-item.model';
import { UserSettings } from '../enums/user-settings.enum';
import { PagedResults } from '../models/paged-results/paged-results.model';
import { FaxFilters } from '../models/user-settings/fax-settings.model';
import { InboundFaxCapture } from '../models/capture-lists/inbound-fax-capture.model';
import { LimitOffsetPagingMeta } from '../models/paged-results/limit-offset-paging.model';
import { YesNo } from '../enums/yes-no.enum';
import { SimilarInboundFax } from '../models/similar-inbound-fax.model';

export class PagedInboundFaxes implements PagedResults {
  inboundFaxes: InboundFax[];
  meta: LimitOffsetPagingMeta;

  get records() {
    return this.inboundFaxes;
  }
}

class NextFaxProvider implements NextQueueItemProvider {
  constructor(private inboundFaxesService: InboundFaxesService) { }

  nextItemInQueueRequest(currentPage: number, pageSize: number, filters: object) {
    return this.nextFaxRequest(currentPage, pageSize, filters as FaxFilters);
  }

  nextItemFromStartRequest(filters) {
    return this.nextFaxRequest(1, 1, filters as FaxFilters);
  }

  private nextFaxRequest(currentPage: number, pageSize: number, filters: FaxFilters) {
    return this.inboundFaxesService
      .getList(
        currentPage,
        pageSize,
        [InboundFaxStatus.fresh],
        filters.selectedClientIds,
        filters.selectedAssignedToIds,
        filters.includeArchived
      )
      .pipe(
        map(resultsData => {
          const results = new PagedInboundFaxes();
          return Object.assign(results, resultsData);
        })
      );
  }
}

class NextMyFaxQueueProvider implements NextQueueItemProvider {
  constructor(private inboundFaxesService: InboundFaxesService) { }

  nextItemInQueueRequest(currentPage: number, pageSize: number, _filters: object) {
    return this.nextFaxRequest(currentPage, pageSize);
  }

  nextItemFromStartRequest(_filters: object) {
    return this.nextFaxRequest(1, 1);
  }

  private nextFaxRequest(currentPage: number, pageSize: number) {
    return this.inboundFaxesService
      .getMyQueue(currentPage, pageSize)
      .pipe(
        map(resultsData => {
          const results = new PagedInboundFaxes();
          return Object.assign(results, resultsData);
        })
      );
  }
}

@Injectable()
export class InboundFaxesService extends ApiService {
  constructor(private http: HttpClient, private queueProcessingService: QueueProcessingService) {
    super();
  }

  get(id: number): Observable<InboundFax> {
    return this.http.get<InboundFax>(`${environment.captureApi.url}/inbound_faxes/${id}`);
  }

  getList(
    page: number,
    pageSize: number,
    statuses: Array<string> = ['fresh'],
    clientIds: Array<string> = [],
    assignedToIds: Array<string> = [],
    includeArchived: boolean = false
  ): Observable<PagedInboundFaxes> {
    const searchParams = new URLSearchParams(`page=${page}&per_page=${pageSize}`);

    statuses.forEach(status => searchParams.append('statuses[]', status));
    clientIds.forEach(clientId => searchParams.append('client_ids[]', clientId));
    assignedToIds.forEach(userId => searchParams.append('assigned_to_ids[]', userId));

    if (includeArchived) {
      searchParams.append('include_archived', YesNo.yes.toString());
    }

    return this.http.get<PagedInboundFaxes>(`${environment.captureApi.url}/inbound_faxes?${searchParams.toString()}`);
  }

  getMyQueue(page: number, pageSize: number): Observable<PagedInboundFaxes> {
    return this.http.get<PagedInboundFaxes>(
      `${environment.captureApi.url}/inbound_faxes/my_queue?page=${page}&per_page=${pageSize}`
    );
  }

  getMyQueueCount(): Observable<number> {
    return this.http.get<{ count: number }>(`${environment.captureApi.url}/inbound_faxes/my_queue_count`)
      .pipe(map(resultsData => resultsData.count));
  }

  create(clientId, calledNumber, callerNumber, pages, assignToMe, signedBlobId): Observable<InboundFax> {
    return this.http.post<InboundFax>(`${environment.captureApi.url}/inbound_faxes`, {
      signed_id: signedBlobId,
      client_id: clientId,
      called_number: calledNumber,
      caller_number: callerNumber,
      pages,
      assign_to_me: assignToMe,
    });
  }

  transition(fax: InboundFax) {
    return this.http.patch<InboundFax>(`${environment.captureApi.url}/inbound_faxes/${fax.id}/transition`, {
      fax: {
        patientId: fax.patient && fax.patient.id,
        sourcePatientId: fax.sourcePatient && fax.sourcePatient.id,
        prescriberId: fax.prescriber && fax.prescriber.id,
        outboundFaxId: fax.outboundFax && fax.outboundFax.id,
        status: fax.status,
        statusReason: fax.status === InboundFaxStatus.notValidEvidence && fax.statusReason ? fax.statusReason : null,
        notes: fax.notes,
        communicationMethod: fax.communicationMethod,
        latestLlmResultId: fax.latestLlmResultId,
        llmPatientsResultValid: fax.llmPatientsResultValid,
        duplicateInboundFaxId: fax.duplicateInboundFax?.id,
        duplicateInboundFaxLcsRatio: fax.duplicateInboundFaxLcsRatio,
      },
    });
  }

  getCaptures(fax: InboundFax): Observable<InboundFaxCapture[]> {
    return this.http.get<InboundFaxCapture[]>(`${environment.captureApi.url}/inbound_faxes/${fax.id}/captures`);
  }

  getSimlarPatientOfficeInboundFaxes(
    fax: InboundFax,
    patientId: number,
    outboundFaxId: number
  ): Observable<SimilarInboundFax[]> {

    const params = new URLSearchParams();
    params.append('patient_id', patientId.toString())
    params.append('outbound_fax_id', outboundFaxId.toString())

    return this.http.get<SimilarInboundFax[]>(
      `${environment.captureApi.url}/inbound_faxes/${fax.id}/similar_patient_office_faxes?${params.toString()}`
    );
  }

  nextFaxProviderFor(source: string): NextQueueItemProvider {
    if (source === 'my') {
      return new NextMyFaxQueueProvider(this);
    } else {
      return new NextFaxProvider(this);
    }
  }

  nextFaxSettingsKey(source: string): UserSettings {
    if (source === 'my') {
      return UserSettings.captureAdminMyFaxQueueSettings;
    } else {
      return UserSettings.captureAdminFaxQueueSettings;
    }
  }

  getNextFax(source: string): Observable<NextQueueItem<InboundFax>> {
    const nextFaxProvider = this.nextFaxProviderFor(source);

    return this.queueProcessingService.getNextItem<InboundFax>(
      this.nextFaxSettingsKey(source),
      nextFaxProvider
    );
  }

  skipToNextFax(source: string): Observable<NextQueueItem<InboundFax>> {
    const nextFaxProvider = this.nextFaxProviderFor(source);

    return this.queueProcessingService.skipToNextItem<InboundFax>(
      this.nextFaxSettingsKey(source),
      nextFaxProvider
    );
  }
}
