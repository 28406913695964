import { Component, OnInit, ViewChild, ElementRef, AfterViewChecked } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Title } from '@angular/platform-browser';
import { Subscription, timer, throwError } from 'rxjs';
import { switchMap, takeWhile, finalize, catchError } from 'rxjs/operators';
import { FormGroup, FormBuilder, FormArray, AbstractControl, Validators, ValidationErrors } from '@angular/forms';
import { LlmPlaygroundProjectsService } from '../../../core/services/llm-playground-projects.service';
import { LlmPlaygroundBatchesService } from '../../../core/services/llm-playground-batches.service';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { Project, Example, Prompt, Chat } from '../../../core/models/llm-playground.model';
import { cloneDeep, filter, sortBy } from 'lodash-es';
import { LlmPlaygroundAddBatchModalComponent } from '../../components/llm-playground-add-batch-modal/llm-playground-add-batch-modal.component';
import { LlmPlaygroundShareModalComponent } from '../../components/llm-playground-share-modal/llm-playground-share-modal.component';
import { LlmModel } from 'app/core/models/llm-model';

@Component({
  selector: 'app-llm-playground-project',
  templateUrl: './llm-playground-project.component.html',
  styleUrls: ['./llm-playground-project.component.scss'],
})
export class LlmPlaygroundProjectComponent implements OnInit, AfterViewChecked {
  @ViewChild('chatContainer') chatContainer: ElementRef;
  project: Project;
  promptForm: FormGroup;
  pollingSubscription: Subscription | undefined;
  pollingStartTime: number;
  responseError = '';
  saveError = '';
  responseLoading = false;
  lastChatUpdatedAt: Date;
  scrollChatToBottom = false;
  isSaving = false;
  addBatchModal: NgbModalRef = null;
  cachedPageState: { form: Prompt; chats: Chat[] };
  currentBatchName: string;
  defaultExample = { id: null, userMessage: '', assistantMessage: '' };
  shouldDisplayExampleDelete = true;
  models: LlmModel[];

  constructor(
    private titleService: Title,
    private route: ActivatedRoute,
    private llmPlaygroundProjectsService: LlmPlaygroundProjectsService,
    private llmPlaygroundBatchesService: LlmPlaygroundBatchesService,
    private authenticationService: AuthenticationService,
    private formBuilder: FormBuilder,
    private router: Router,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.loadModels();
    const projectId = parseInt(this.route.snapshot.paramMap.get('id'), 10);
    this.loadProject(projectId);
  }

  ngAfterViewChecked(): void {
    if (this.chatContainer && this.scrollChatToBottom) {
      const container = this.chatContainer.nativeElement;
      container.scrollTop = container.scrollHeight;
      this.scrollChatToBottom = false;
    }
  }

  get isOwner() {
    return this.authenticationService.currentUser.email === this.project?.createdByEmail
  }

  get viewingBatchResults() {
    return !!this.cachedPageState;
  }

  get chats() {
    return this.project.prompt.chats;
  }

  get examples(): FormArray {
    return this.promptForm.get('examples') as FormArray;
  }

  get model() {
    return this.promptForm.get('model');
  }

  get temperature() {
    return this.promptForm.get('temperature');
  }

  get useJsonMode() {
    return this.promptForm.get('useJsonMode');
  }

  get totalTokens() {
    return this.showingBatchRun() ? '--' : this.project.prompt.totalTokens;
  }

  get batches() {
    return this.project?.batches || [];
  }

  get modelName() {
    if (this.project.prompt.model.startsWith("auto")) {
      return this.project.prompt.model;
    } else {
      const resolvedModel = this.models.find((m) => m.name === this.project.prompt.model);
      return resolvedModel?.displayName || this.project.prompt.model;
    }
  }

  get disableAutomatedSubmission() {
    return this.temperature.errors?.required || this.temperature.errors?.min || this.temperature.errors?.max ||
      this.responseLoading
  }

  get allChatsUsedTheSameModel() {
    return this.project.prompt.chats.length > 0 &&
      this.project.prompt.chats.every(chat => chat.model === this.project.prompt.chats[0].model)
  }

  resolveMaxInputTokens(modelName: string): number {
    let relevantModel: LlmModel;

    if (modelName.startsWith("auto")) {
      const filteredModels = filter(this.models, (m) => m.autoKeyword === modelName);
      relevantModel = sortBy(filteredModels, (m) => m.maxInputTokens).at(-1);
    } else {
      relevantModel = this.models.find((llmModel) => llmModel.name === modelName);
    }

    if (relevantModel) {
      return relevantModel.maxInputTokens;
    } else {
      return 0;
    }
  }

  shouldDisplayHr(index: number): boolean {
    return index !== 0 && this.examples.controls.slice(0, index).filter(control => !control.value._destroy).length > 0;
  }

  onShareProject(event: Event) {
    event.preventDefault();
    const modalRef = this.modalService.open(LlmPlaygroundShareModalComponent, {
      windowClass: 'edit',
    });
    modalRef.componentInstance.projectId = this.project.id;
    modalRef.componentInstance.createdByEmail = this.project.createdByEmail;
  }

  onDeleteProject(event: Event) {
    event.preventDefault();
    const confirmed = window.confirm(
      'Are you sure you want to delete this project along with all of its examples and batch runs?'
    );
    if (confirmed) {
      this.llmPlaygroundProjectsService.destroy(this.project.id).subscribe(() => {
        this.router.navigate(['../'], { relativeTo: this.route });
      });
    }
  }

  onOpenAddBatchModal() {
    this.onPromptSave(false);
    const modalRef = this.modalService.open(LlmPlaygroundAddBatchModalComponent, {
      windowClass: 'edit',
    });
    modalRef.componentInstance.projectId = this.project.id;

    modalRef.result.then(batch => {
      if (batch) {
        this.project.batches = [batch, ...this.project.batches];
      }
    });
  }

  onEnterSubmit(event: Event) {
    event.preventDefault();
    if (!this.promptForm.invalid) {
      this.onPromptSave(true);
    }
  }

  onPromptSave(submit: boolean = false) {
    if (submit) {
      this.lastChatUpdatedAt = new Date(this.project.prompt.chats.slice(-1)[0]?.updatedAt || 0);
    } else {
      this.isSaving = true;
    }

    this.llmPlaygroundProjectsService.update({ id: this.project.id, prompt: this.promptForm.value }, submit).subscribe(
      response => {
        this.project = response;
        this.responseError = '';
        this.saveError = '';
        setTimeout(() => {
          this.isSaving = false;
        }, 1000);

        this.updateForm();

        if (submit) {
          this.scrollChatToBottom = true;
          this.startPolling();
        }
      },
      errorResp => {
        this.isSaving = false;
        this.saveError = this.getErrorMessage(errorResp);
      }
    );
  }

  onResubmitFirst(): void {
    const firstUserMessage = this.project.prompt.chats[0]?.userMessage;

    this.onPromptSave(false);
    this.llmPlaygroundProjectsService.destroyChatHistory(this.project).subscribe(response => {
      this.project = response;
      this.project.prompt.userMessage = firstUserMessage;
      this.updateForm();
      this.onPromptSave(true);
    });
  }

  onClearChat(): void {
    this.llmPlaygroundProjectsService.destroyChatHistory(this.project).subscribe(response => {
      this.project = response;
    });
  }

  getErrorMessage(errorResp: any): string {
    if (errorResp.error.error) {
      return errorResp.error.error;
    }
    if (errorResp.error.errors) {
      return errorResp.error.errors.join('\n');
    }
    return 'An unknown error occurred saving the project';
  }

  onViewBatchPrompt(id: number): void {
    this.currentBatchName = this.project.batches.find(batch => batch.id === id)?.name;

    if (!this.cachedPageState) {
      this.cachedPageState = {
        form: cloneDeep(this.promptForm.value),
        chats: this.project.prompt.chats,
      };
    }
    this.llmPlaygroundBatchesService.prompt(id).subscribe(prompt => {
      this.project.prompt = prompt;
      this.project.prompt.chats = [];
      this.updateForm();
    });
  }

  onPromptBack(): void {
    this.project.prompt = this.cachedPageState.form;
    this.project.prompt.chats = this.cachedPageState.chats;
    this.cachedPageState = null;
    this.updateForm();
  }

  onCopyBatchPrompt(): void {
    this.project.prompt = cloneDeep(this.promptForm.value);
    this.project.prompt.id = this.cachedPageState.form.id;
    this.project.prompt.chats = [];
    const oldExamples = this.cachedPageState.form.examples.
      filter(example => example.id).
      map(example => {
        example._destroy = true;
        return example;
      });

    const newExamples = this.project.prompt.examples.map(example => {
      example.id = null; //so we create new ones on the backend.
      return example;
    });
    this.project.prompt.examples = [...oldExamples, ...newExamples];
    this.cachedPageState = null;
    this.updateForm();
  }

  onDeleteBatchRun(id: number): void {
    this.llmPlaygroundBatchesService.destroy(id).subscribe(() => {
      this.project.batches = this.project.batches.filter(batch => batch.id !== id);
    });
  }

  onExampleChange(): void {
    this.shouldDisplayExampleDelete = !this.isDefaultExample();
  }

  isDefaultExample(): boolean {
    const activeExamples = this.examples.controls.filter(control => !control.value._destroy);

    if (activeExamples.length === 1) {
      const example = activeExamples[0].value;
      const isUserMessageBlank = !example.userMessage || example.userMessage.trim() === '';
      const isAssistantMessageBlank = !example.assistantMessage || example.assistantMessage.trim() === '';
      return isUserMessageBlank && isAssistantMessageBlank;
    }

    return false;
  }

  addExample(): void {
    const examplesArray = this.promptForm.get('examples') as FormArray;
    this.shouldDisplayExampleDelete = examplesArray.length !== 0;
    const newControl = this.exampleFormBuilderGroup(this.defaultExample);
    examplesArray.insert(examplesArray.length, newControl);
  }

  deleteExample(event: Event, index: number): void {
    event.preventDefault();
    const examplesArray = this.promptForm.get('examples') as FormArray;
    if (examplesArray.at(index).value.id) {
      examplesArray.at(index).patchValue({ _destroy: true });
    } else {
      examplesArray.removeAt(index);
    }

    const hasActiveExamples = examplesArray.controls.some(control => !control.value._destroy);
    if (!hasActiveExamples) {
      this.addExample();
    } else {
      this.shouldDisplayExampleDelete = !this.isDefaultExample();
    }
  }

  private startPolling(): void {
    this.responseLoading = true;
    this.pollingStartTime = Date.now();
    const POLLING_INTERVAL = 3000;
    const DURATION = 120000; // 120 seconds is the Azure OpenAI timeout

    this.pollingSubscription = timer(0, POLLING_INTERVAL)
      .pipe(
        switchMap(() => this.llmPlaygroundProjectsService.get(this.project.id)),
        takeWhile(() => {
          if (Date.now() - this.pollingStartTime < DURATION) {
            return true;
          } else {
            this.responseError = 'Request for data timed out.';
            return false;
          }
        }, true),
        finalize(() => this.responseLoading = false)
      )
      .subscribe(
        response => {
          this.project = response;
          if (this.chatIsUpdated()) {
            this.scrollChatToBottom = true;
            this.pollingSubscription.unsubscribe();
          }
        },
        error => {
          console.error('An error occurred:', error);
          this.responseError = 'Error occurred during polling.';
          this.pollingSubscription.unsubscribe();
        }
      );
  }

  private showingBatchRun(): boolean {
    return this.project.prompt.chats.some(chat => chat.batchIndex > 0);
  }

  private loadModels() {
    this.llmPlaygroundProjectsService.getModels().subscribe((models) => this.models = models);
  }

  private loadProject(projectId: number) {
    this.llmPlaygroundProjectsService.get(projectId).pipe(
      catchError(error => throwError(error))
    ).subscribe({
      next: (project) => {
        this.project = project;
        this.titleService.setTitle(project.name);
        this.shouldDisplayExampleDelete = this.project.prompt.examples.length !== 0;
        this.scrollChatToBottom = true;
        this.buildForm();
      },
      error: (error) => {
        if (error.status === 404) {
          this.router.navigate(['/404'])
        } else {
          console.log('Error fetching project: ', error.status)
        }
      }
    });
  }

  private exampleFormBuilderGroup(example: Example) {
    return this.formBuilder.group({
      id: [example.id],
      userMessage: [example.userMessage],
      assistantMessage: [example.assistantMessage],
      _destroy: !!example._destroy,
    });
  }

  private chatIsUpdated(): boolean {
    const lastChat = this.project.prompt.chats.slice(-1)[0];
    const currentUpdatedAt = new Date(lastChat?.updatedAt);
    const status = lastChat?.status;
    return currentUpdatedAt > this.lastChatUpdatedAt && status !== 'initialized' && status !== 'processing';
  }

  private activeExampleLength(examples: Example[]): number {
    return examples.filter(example => (example._destroy !== true)).length
  }

  private updateForm(): void {
    const prompt = this.project.prompt;
    this.shouldDisplayExampleDelete = this.activeExampleLength(prompt.examples) !== 0;

    this.promptForm.reset({
      id: prompt.id,
      model: prompt.model,
      temperature: prompt.temperature,
      systemMessage: prompt.systemMessage,
      userMessage: prompt.userMessage,
      useJsonMode: prompt.useJsonMode,
    });
    this.updateExamples(prompt.examples);
  }

  private updateExamples(examples: Example[]): void {
    const formArray = this.promptForm.get('examples') as FormArray;
    formArray.clear();
    const newExamples = examples.length === 0 ? [this.defaultExample] : examples;
    newExamples.forEach((example: Example) => {
      formArray.push(this.exampleFormBuilderGroup(example));
    });
  }

  private buildForm(): void {
    const prompt = this.project.prompt;
    const newExamples = this.activeExampleLength(prompt.examples) === 0 ? [this.defaultExample] : prompt.examples;
    this.promptForm = this.formBuilder.group({
      id: [prompt.id],
      model: [prompt.model, Validators.required],
      temperature: [prompt.temperature, [Validators.required, Validators.min(0), Validators.max(2)]],
      systemMessage: [prompt.systemMessage],
      userMessage: [prompt.userMessage, Validators.required],
      examples: this.formBuilder.array(newExamples.map((example: Example) => this.exampleFormBuilderGroup(example))),
      useJsonMode: [prompt.useJsonMode],
    });
  }
}
