<div class="card mt-1 bg-light">
  <div class="card-body">
    <h4>Upload Fax</h4>
    <div class="alert alert-danger" *ngIf="hasError">
      There was a problem uploading fax. All fields are required. Please try again.
      <ul class="mt-2">
        <li *ngFor="let error of errors">{{ error }}</li>
      </ul>
    </div>
    <section class="row mt-1">
      <div class="mb-3 col-12">
        <label for="selctedClient">Select A Client</label>
        <select class="form-select" [(ngModel)]="selectedClientId" (change)="onClientChange()" name="selectedClient">
          <option [ngValue]="null"> -- Select Client -- </option>
          <option *ngFor="let client of clients" [ngValue]="client.id">{{ client.name }}</option>
        </select>
      </div>

      <div class="mb-3 col-12">
        <label for="callerNumber">Received From Fax Number</label>
        <input
          [(ngModel)]="callerNumber"
          required
          type="text"
          class="form-control"
          id="callerNumber"
          name="callerNumber"
        />
      </div>

      <div class="mb-3 col-12">
        <label for="calledNumber">Sent To Fax Number</label>
        <input
          [(ngModel)]="calledNumber"
          required
          type="text"
          class="form-control"
          id="calledNumber"
          name="calledNumber"
        />
      </div>

      <div class="mb-3 col-12">
        <label for="pages">Pages</label>
        <input [(ngModel)]="pages" required type="number" class="form-control" id="pages" name="pages" />
      </div>

      <div class="mb-3 col-12" *ngIf="displayAssignToMe">
        <div><em>Optional: Selecting this will assign the upload(s) to your My Faxes queue.</em></div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" [(ngModel)]="assignToMe" type="checkbox" id="assignToMe" name="assignToMe" />
          <label class="form-check-label" for="assignToMe">Assign To Me</label>
        </div>
      </div>


      <div class="mb-3 col-12" [hidden]="!canUpload()">
        <app-file-upload
          [hidden]="uploadingFile"
          (uploadStart)="onUploadStart()"
          (uploadComplete)="onUploadComplete($event)"
          (uploadError)="onUploadError($event)"
        />
        <div class="py-2" [hidden]="!uploadingFile">
          <fa-icon [icon]="['fas', 'circle-notch']" size="sm" [animation]="'spin'" />
          Uploading file...
        </div>
      </div>
    </section>
  </div>
</div>
