import { Component, Input, OnInit } from '@angular/core';
import { PatientAttachment } from '../../../core/models/patient-attachment.model';
import { PatientAttachmentListing } from '../../../core/models/patient-attachment-listing.model';
import { PatientAttachmentStatus } from '../../../core/enums/patient-attachment-status.enum';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { keyedPatientAttachmentStatusOptions } from '../../../core/options/patient-attachment-status.opts';

@Component({
  selector: 'app-consult-note-header',
  templateUrl: './consult-note-header.component.html',
  styleUrls: ['./consult-note-header.component.scss'],
})
export class ConsultNoteHeaderComponent implements OnInit {
  @Input() patientAttachment: PatientAttachment;

  canViewPatient = false;
  openRelatedAttachments: PatientAttachmentListing[];
  openRelatedAttachmentsCount: number;
  statusOptions = keyedPatientAttachmentStatusOptions;

  constructor(
    private authService: AuthenticationService,
  ) { }

  ngOnInit() {
    this.canViewPatient = this.authService.isCaptureAdminUser;
    this.handleOpenRelatedAttachments();
  }

  handleOpenRelatedAttachments() {
    this.openRelatedAttachments = this.patientAttachment.relatedPatientAttachments.filter(
      (patientAttachmentListing: PatientAttachmentListing) =>
        patientAttachmentListing.status === PatientAttachmentStatus.open
    )
    this.openRelatedAttachmentsCount = this.openRelatedAttachments.length;
  }
}
