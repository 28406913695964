<app-import-nav />

<h5 class="mt-3">Upload Fax Merge File</h5>
<div class="row g-0" [hidden]="uploading">
  <div class="col-12">
    <div class="alert alert-danger" *ngIf="hasError">
      There was a problem uploading the file.
      <ul class="mt-2">
        <li *ngFor="let error of errors">{{ error }}</li>
      </ul>
    </div>
    <div>
      <app-file-upload
        [fileType]="'.csv'"
        (uploadStart)="onUploadStart()"
        (uploadCancel)="onUploadCancel()"
        (uploadError)="onUploadError()"
        (uploadComplete)="onUploadComplete($event)"
      />
    </div>
  </div>
</div>
<div class="row g-0" [hidden]="!uploading">
  <div class="col-12">
    <fa-icon [icon]="['fas', 'circle-notch']" size="sm" [animation]="'spin'" />
    Uploading File...
  </div>
</div>
<div class="mt-3 border p-3 rounded">
  <strong>File Requirements</strong>
  <ol class="mx-3 ps-2">
    <li class="py-2">
      Required fields
      <ul class="ps-2">
        <li><code>patient_prescriber_id</code></li>
        <li><code>client</code></li>
        <li><code>prescriber_npi</code></li>
        <li><code>patient_id</code></li>
        <li><code>fax</code></li>
      </ul>
    </li>
    <li class="py-2">
      Example file
      <div><small><strong>NOTE:</strong> Extra columns will be ignored</small></div>
      <div class="bg-light p-2 border w-100">
        <strong><code class="csv-header">patient_prescriber_id,client,prescriber_npi,patient_id,fax</code></strong><br />
        <code>842830,adelante,9392048230,8482021,15553459860</code><br />
      </div>
    </li>
    <li class="py-2"><code>fax</code> is the receiving fax number of the practice</li>
    <li class="py-2"><code>.csv</code> only</li>
  </ol>
</div>
