import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PdfFile } from '../../../core/interfaces/pdf-file';
import { PdfFileType } from '../../../core/enums/pdf-file-type.enum';
import { environment } from '../../../../environments/environment';
import { AuthenticationService } from '../../../core/services/authentication.service';

@Component({
  selector: 'app-pdf-basic-viewer',
  templateUrl: './pdf-basic-viewer.component.html',
})
export class PdfBasicViewerComponent implements OnInit {
  @Input() pdfFile: PdfFile;
  @Input() pdfFileType: PdfFileType;
  @Input() height: string;

  @Input() pdfFileViewerClass: string;
  @Input() pdfKitViewerClass: string;

  @Input() pdfKitViewerContainerClass = 'pspdfkit-container';

  @Output() urlChange = new EventEmitter<string>();

  usePdfKitViewer: boolean;

  constructor(
    private authService: AuthenticationService,
  ) { }

  ngOnInit() {
    this.usePdfKitViewer = environment.featureData?.usePdfKitViewer &&
      this.authService.isCaptureAdminUser;
  }

  onUrlChange(url: string) {
    this.urlChange.emit(url);
  }
}
