import { Component, EventEmitter, Input, OnInit, Output, OnChanges } from '@angular/core';
import { Patient } from '../../../core/models/patient.model';
import { Prescriber } from '../../../core/models/prescriber.model';
import { PatientService } from '../../../core/services/patient.service';
import { MinimalProvider } from '../../../core/models/minimal-provider.model';

@Component({
  selector: 'app-select-prescriber',
  templateUrl: './select-prescriber.component.html',
  styleUrls: ['./select-prescriber.component.scss'],
})
export class SelectPrescriberComponent implements OnInit, OnChanges {
  prescribers: Prescriber[] = new Array<Prescriber>();
  refreshing = false;

  @Input() selectedPatient: Patient;
  @Input() selectedProvider: MinimalProvider;

  @Output() public selectionChange = new EventEmitter<Prescriber>();

  constructor(private patientService: PatientService) {}

  ngOnInit() {}

  ngOnChanges() {
    if (this.selectedPatient) {
      this.loadPatientPrescribers();
    } else {
      this.prescribers = new Array<Prescriber>();
    }
  }

  loadPatientPrescribers() {
    this.refreshing = true;

    this.patientService.getPrescribers(this.selectedPatient.id).subscribe((prescribers: Prescriber[]) => {
      this.prescribers = prescribers;
      this.refreshing = false;
    });
  }

  onPrescriberSelect(prescriber: Prescriber) {
    this.prescribers = new Array<Prescriber>();
    this.selectionChange.emit(prescriber);
  }

  onPrescriberClear() {
    this.prescribers = new Array<Prescriber>();
    this.selectionChange.emit(null);
  }
}
