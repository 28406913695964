<div class="card mt-1">
  <div class="card-body">
    <div class="row">
      <div class="col-9">
        <h4>Verification</h4>
      </div>
      <div class="col-3">
        <app-verified-invalid-reviewed [capture]="capture" />
      </div>
    </div>

    <div *ngIf="!processing; else: spinner">
      <ng-container *ngIf="canEdit">
        <app-verification-outcome-status
          [capture]="capture"
          [patientPrescriberNote]="patientPrescriber.latestNote"
        />
        <app-verification-outcome-form
          [capture]="capture"
          [showValidationSummary]="showValidationSummary"
          (transitionChange)="onTransitionChange()"
          (processOutcome)="onProcessOutcome($event)"
          [patientPrescriberNote]="patientPrescriber.latestNote"
        />
      </ng-container>
      <div *ngIf="!canEdit">{{ capture.status | captureStatus }}</div>
      <app-capture-client-prescriber *ngIf="showClientPrescriber" [capture]="capture" />
    </div>
    <div class="alert alert-danger" *ngIf="serverError">
      A server error occurred while processing this match. Please try again or contact support.
      <div class="server-errors">
        <table>
          <thead class="table-dark">
            {{
              serverErrorResponse.summary
            }}
          </thead>
          <tr *ngFor="let serverErrorDetail of serverErrorResponse.details">
            <td>{{ serverErrorDetail }}</td>
          </tr>
        </table>
      </div>
    </div>
    <div class="alert alert-danger" *ngIf="clientError">
      {{ clientError }}
    </div>
  </div>
</div>
<ng-template #spinner>
  <div class="py-2">
    <fa-icon [icon]="['fas', 'circle-notch']" size="sm" [animation]="'spin'" />
    Processing match...
  </div>
</ng-template>
