import { Component, OnDestroy, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { PatientAttachment } from '../../../core/models/patient-attachment.model';
import { PatientAttachmentsService } from '../../../core/services/patient-attachments.service';
import { NavigationService } from '../../../core/services/navigation.service';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { UserTimingService } from '../../../core/services/user-timing.service';
import { PdfFileType } from '../../../core/enums/pdf-file-type.enum';
import { keyedPatientAttachmentStatusOptions } from '../../../core/options/patient-attachment-status.opts';
import { UserPresenceService } from 'app/core/services/user-presence.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-consult-note-related',
  templateUrl: './consult-note-related.component.html',
  styleUrls: ['./consult-note-related.component.scss'],
})
export class ConsultNoteRelatedComponent implements OnInit, OnDestroy {
  patientAttachmentId: string;
  patientAttachment: PatientAttachment;
  selectedAttachment: PatientAttachment;
  loading = true;
  selectedLoading = false;
  pdfFileType: PdfFileType = PdfFileType.patientAttachment;
  statusOptions = keyedPatientAttachmentStatusOptions;
  usePdfKitViewer: boolean;

  constructor(
    private route: ActivatedRoute,
    private patientAttachmentsService: PatientAttachmentsService,
    private titleService: Title,
    private authService: AuthenticationService,
    private userTimingService: UserTimingService,
    private userPresenceService: UserPresenceService,
    private navigationService: NavigationService
  ) { }

  ngOnInit() {
    this.patientAttachmentId = this.route.snapshot.paramMap.get('id');
    this.loadPatientAttachments(this.patientAttachmentId);
    this.userPresenceService.subscribeAndJoin("consultNote", this.patientAttachmentId)
    this.usePdfKitViewer = environment.featureData?.usePdfKitViewer && this.authService.isCaptureAdminUser;
  }

  ngOnDestroy() {
    this.stopTimeTracking();
    this.userPresenceService.leaveAndUnsubscribe("consultNote", this.patientAttachmentId)
  }

  onDisplayDocument(id: number) {
    if (id === this.patientAttachment.listing.id) {
      this.selectedAttachment = this.patientAttachment
    } else {
      this.loadSelectedAttachment(id)
    }
  }

  private loadPatientAttachments(patientAttachmentId: string): void {
    this.loading = true;

    this.patientAttachmentsService.get(patientAttachmentId).subscribe(
      result => {
        this.patientAttachment = result;
        this.selectedAttachment = result;
        this.startTimeTracking(this.patientAttachment);
        this.setTitle();
        this.loading = false;
      },
      (err: HttpErrorResponse) => {
        if (err.status === 404) {
          this.navigationService.notFound();
        } else {
          console.error(err);
          this.loading = false;
        }
      }
    );
  }

  private loadSelectedAttachment(id: number) {
    this.selectedLoading = true;
    this.selectedAttachment = null;

    this.patientAttachmentsService.get(id).subscribe(
      result => {
        this.selectedAttachment = result;
        this.selectedLoading = false;
      },
      (err: HttpErrorResponse) => {
        console.error(err);
        this.selectedLoading = false;
      }
    );
  }

  private setTitle() {
    const title = `R1 340B Recovery - ${this.patientAttachment.id} | Related Patient Documents`;
    this.titleService.setTitle(title);
  }

  private startTimeTracking(patientAttachment: PatientAttachment) {
    if (this.authService.isCaptureAdminUser) {
      this.userTimingService.track({
        page: 'other_patient_consult_notes',
        recordId: patientAttachment.id,
        status: patientAttachment.status,
      });
    }
  }

  private stopTimeTracking() {
    if (this.authService.isCaptureAdminUser) {
      this.userTimingService.stop();
    }
  }
}
