import { Component, Input, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { ClientNetSavingsTrendBySources, ClientNetSavingsTrendBySourcesDataset } from 'app/core/models/client-net-savings-trend-by-sources.model';
import { Chart } from 'chart.js/auto';

const BORDER_COLORS = [
  'rgb(217,157,11)',
  'rgb(130,0,59)',
  'rgb(67,142,255)',
  'rgb(0,166,144)',
  'rgb(115,120,128)',
  'rgb(255,104,0)',
  'rgb(99,225,255)',
  'rgb(204,61,73)',
  'rgb(136,224,151)',
  'rgb(180,185,191)',
];

@Component({
  selector: 'app-client-net-savings-trend-by-source-graph',
  templateUrl: './client-net-savings-trend-by-source-graph.component.html',
  styleUrls: ['./client-net-savings-trend-by-source-graph.component.scss'],
})
export class ClientNetSavingsTrendBySourceGraphComponent implements AfterViewInit {
  @Input() clientNetSavingsTrendBySources: ClientNetSavingsTrendBySources;
  @Input() canvasId = 'canvas';

  chart: any = [];

  constructor(private cdr: ChangeDetectorRef) {}

  ngAfterViewInit() {
    this.init();
    // see https://stackoverflow.com/questions/34364880/expression-has-changed-after-it-was-checked
    // since the chart attribute changes from [] to an actual chart, this was throwing the
    // expression has changed after it was checked error.
    //
    // We also need to build the chart after the view is init, because the chart js requires the html element
    // id to match the id of the chart, otherwise it can't get the context.
    this.cdr.detectChanges();
  }

  private init() {
    this.buildChart();
  }

  private buildChart() {
    this.chart = new Chart(this.canvasId, {
      type: 'line',
      data: {
        labels: this.clientNetSavingsTrendBySources.labels,
        datasets: this.datasetsWithColor(this.clientNetSavingsTrendBySources.datasets),
      },
      options: {
        responsive: true,
        scales: {
          y: {
            beginAtZero: false,
            ticks: {
              callback: function(value, index, ticks) {
                return '$' + value;
              }
            }
          },
        },
        elements: {
          line: {
            borderWidth: 1,
          }
        },
        plugins: {
          tooltip: {
            callbacks: {
              label: function(context) {
                const label = context.dataset.label || '';
                const value = context.formattedValue;
                return label + ': ' + '$' + value;
              }
            }
          }
        }
      },
    });
  }

  private datasetsWithColor(datasets: ClientNetSavingsTrendBySourcesDataset[]) {
    return datasets.map( (dataset, i) => {
      const { label, data } = dataset;
      const borderColor = BORDER_COLORS[i % BORDER_COLORS.length];
      const backgroundColor = borderColor;

      return {
        label,
        data,
        borderColor,
        backgroundColor,
      }
    });
  }

}