<div class="p-2">
  <ng-container *ngIf="!refreshing; else: spinner" class="faxes-detail">

    <div class="row mx-0 mb-2" *ngIf="showTabs">
      <div class="col-6 px-0 text-start">
        <nav class="nav nav-pills">
          <a class="nav-item nav-link active"
             href="javascript:;"
             (click)="changeTab('main')"
             [class.active]="currentTab === 'main'">
            Inbound Fax
          </a>

          <span [matTooltip]="duplicateDocumentsTabTooltip" matTooltipPosition="above">
            <a class="nav-item nav-link"
               href="javascript:;"
               (click)="changeTab('duplicate-documents')"
               [class.active]="currentTab === 'duplicate-documents'"
               [class.disabled]="similarInboundFaxState.similarInboundFaxes.length === 0">
              Duplicate Documents
            </a>
          </span>
        </nav>
      </div>
    </div>

    <app-inbound-fax-main-tab
      [hidden]="currentTab !== 'main'"
      [inboundFax]="inboundFax"
      [inboundFaxInitialState]="inboundFaxInitialState"
      [similarInboundFaxState]="similarInboundFaxState"
      (inboundFaxAttributeChanged)="onInboundFaxAttributeChange()"
      (tabChangeRequested)="onTabChangeRequested($event)"
      (nextFaxRequested)="onNextFaxRequested()"
      (skipToNextFaxRequested)="onSkipToNextFaxRequested()"
      (navigateToQueueRequested)="onNavigateToQueueRequested()" />

    <app-inbound-fax-duplicate-documents-tab
      [hidden]="currentTab !== 'duplicate-documents'"
      [inboundFax]="inboundFax"
      [similarInboundFaxState]="similarInboundFaxState"
      (duplicateCheckPromptSubmitted)="onDuplicateCheckPromptSubmitted($event)" />

  </ng-container>
  <ng-template #spinner> <mat-spinner /> </ng-template>
</div>
