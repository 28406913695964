<form *ngIf="capture.outboundFaxesEnabled; else: faxingDisabled">
  <div class="mb-3">
    <label><strong>Choose Prescriber:</strong></label>
    <ng-select
      class="w-50"
      name="prescriber"
      bindValue="id"
      [multiple]="false"
      [items]="prescribers"
      [clearable]="false"
      [searchable]="false"
      (change)="onPrescriberChange()"
      [(ngModel)]="formModel.selectedPrescriberId">

      <ng-template ng-label-tmp let-prescriber="item">
        {{ prescriber.provider.fullName }}
      </ng-template>

      <ng-template ng-option-tmp let-prescriber="item">
        {{ prescriber.provider.fullName }}
      </ng-template>

    </ng-select>
  </div>
  <div *ngIf="!loadingProviderOffices; else: providerOfficesSpinner">
    <div *ngIf="providerOffices.length > 0; else: noProviderOffices">
      <table class="table table-sm border">
        <thead>
          <tr>
            <th>OFFICE</th>
            <th>ADDRESS</th>
            <th>FAX NUMBER</th>
            <th>COMM METHOD</th>
            <th>PPF STATUS</th>
            <th>PROVIDER FAX STATUS</th>
            <th class="text-end">CREATED DATE</th>
            <th class="text-end">MODIFIED DATE</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let providerOffice of providerOffices">
            <td>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  [value]="providerOffice.id"
                  [id]="'checkBox' + providerOffice.id"
                  name="providerOfficeId"
                  [(ngModel)]="formModel.selectedProviderOfficeId"
                  (change)="onOfficeSelectionChange()"
                />
                <label class="form-check-label" [for]="'checkBox' + providerOffice.id">
                  {{
                    providerOffice.office.officeNames &&
                      providerOffice.office.officeNames.join(', ')
                  }}
                </label>
              </div>
            </td>
            <td>
              <div>{{ providerOffice.office.address1 }}</div>
              <div>{{ providerOffice.office.address2 }}</div>
              <div>
                {{ providerOffice.office.city }},
                {{ providerOffice.office.state }} {{ providerOffice.office.zip }}
              </div>
            </td>
            <td><app-office-fax [office]="providerOffice.office" /></td>
            <td>{{ providerOffice.office.communicationMethod }}</td>
            <td>{{ patientPrescriberOfficeStatus(providerOffice) }}</td>
            <td>{{ keyedProviderOfficeStatusOptions[providerOffice.status]?.display }}</td>
            <td class="text-end">{{ providerOffice.createdAt | date: 'MM/dd/yyyy' }}</td>
            <td class="text-end">{{ providerOffice.updatedAt | date: 'MM/dd/yyyy' }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="mb-3">
    <label><strong>Choose Template:</strong></label>
    <ng-select
      class="w-50"
      name="template"
      bindValue="value"
      bindLabel="display"
      [multiple]="false"
      [items]="faxTemplateOptions"
      [clearable]="false"
      [searchable]="false"
      (change)="onTemplateChange()"
      [(ngModel)]="formModel.selectedTemplate"
    >
      <ng-template ng-header-tmp>
        <i>Suggested Template: </i><strong>{{ outboundFaxSuggestedTemplate }}</strong>
      </ng-template>
    </ng-select>
  </div>
  <div class="mb-3" *ngIf="displayCustomNote">
    <label for="customNote"><strong>Custom Note:</strong></label>
    <textarea
      id="customNote"
      name="customNote"
      class="form-control w-50"
      rows="4"
      (ngModelChange)="onCustomNoteChange()"
      [maxLength]="maxCustomNoteLength"
      [(ngModel)]="formModel.customNote"
    >
    </textarea>
    <small *ngIf="formModel.customNote" class="form-text text-muted">
      {{ formModel.customNote.length }} of {{ maxCustomNoteLength }} characters
    </small>
  </div>
  <div class="mt-2 actions">
    <button type="button" class="btn btn-primary" [disabled]="!sendFaxEnabled" (click)="onSendFaxClick()">
      <fa-icon *ngIf="sendingFax" [icon]="['fas', 'circle-notch']" size="sm" [animation]="'spin'" />
      Send Fax
    </button>
  </div>
  <ng-template #providerOfficesSpinner> <mat-spinner diameter="30" /> </ng-template>
  <ng-template #noProviderOffices> <p>No provider offices found.</p></ng-template>
</form>

<ng-template #faxingDisabled>
  <div class="alert alert-warning p-2">
    Faxing has been disabled for this client.
  </div>
</ng-template>
