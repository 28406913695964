import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { PatientAttachment } from '../../../core/models/patient-attachment.model';
import { Capture } from '../../../core/models/capture.model';
import { PdfFileType } from '../../../core/enums/pdf-file-type.enum';
import { PatientAttachmentsService } from '../../../core/services/patient-attachments.service';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { environment } from '../../../../environments/environment';
import { EhrConsultNoteReference } from 'app/core/models/ehr-consult-note-reference.model';

@Component({
  selector: 'app-patient-document',
  templateUrl: './patient-document.component.html',
  styleUrls: ['./patient-document.component.scss'],
})
export class PatientDocumentComponent implements OnInit, OnChanges {
  @Input() capture: Capture;
  @Input() selectedAttachment: PatientAttachment;

  pdfFileType: PdfFileType = PdfFileType.patientAttachment;
  initialPage = null;
  activePage = null;
  hidePdfRotationControls = false;
  usePdfKitViewer: boolean;

  constructor(
    private authService: AuthenticationService,
    private patientAttachmentsService: PatientAttachmentsService,
  ) { }

  ngOnInit() {
    this.hidePdfRotationControls = !this.authService.isCaptureAdminUser;
    this.usePdfKitViewer = environment.featureData?.usePdfKitViewer
    this.initialize();
    this.patientAttachmentsService.patientAttachmentChanged.subscribe(({ patientAttachment }) => {
      if (patientAttachment.id === this.selectedAttachment.id) {
        this.handlePatientAttachmentChange(patientAttachment);
      }
    });
  }

  ngOnChanges() {
    this.initialize();
  }

  onEhrConsultNoteReferenceUpdated(updatedEhrConsultNoteReference: EhrConsultNoteReference) {
    this.selectedAttachment.ehrConsultNoteReference = updatedEhrConsultNoteReference;
  }

  public onPageChange(page: number) {
    this.activePage = page;
  }

  public onUrlChange(url: string) {
    this.selectedAttachment.url = url;
  }

  public onDocumentClick($event: MouseEvent) {
    if ($event.altKey) {
      this.updatePatientAttachment();
    }
  }

  private initialize() {
    this.initialPage = (this.selectedAttachment && this.selectedAttachment.medicationsPage) || 1;
    this.activePage = this.initialPage;
  }

  private updatePatientAttachment() {
    this.selectedAttachment.medicationsPage = this.activePage;

    this.patientAttachmentsService.update(this.selectedAttachment).subscribe(pa => {
      this.patientAttachmentsService.notifyPatientAttachmentChanged(pa);
    });
  }

  private handlePatientAttachmentChange(patientAttachment: PatientAttachment) {
    this.patientAttachmentsService
      .getStatus(patientAttachment.id)
      .subscribe((result) => {
        patientAttachment.hasMatchingSpecialtyPmvs = result.hasMatchingSpecialtyPmvs;
        this.selectedAttachment = patientAttachment;
      });
  }
}
