<div class="card">
  <div class="card-body">
    <h5 class="mb-4">Patient Demographics</h5>

    <div class="row">
      <div class="col">
        <strong>FIRST NAME:</strong>
        <div>{{ patient.firstName }}</div>
      </div>

      <div class="col">
        <strong>LAST NAME:</strong>
        <div>{{ patient.lastName }}</div>
      </div>

      <div class="col">
        <strong>DOB:</strong>
        <div>{{ patient.dob |  date: 'MM/dd/yyyy' }}</div>
      </div>

      <div class="col">
        <strong>GENDER:</strong>
        <div>{{ patient.gender | gender }}</div>
      </div>

      <div class="col">
        <strong>EMPI:</strong>
        <div>
          {{ patient.empi | blankValueDash }}
        </div>
      </div>

      <div class="col">
        <strong>EHR INSTANCE:</strong>
        <div>{{ patient.ehrInstance | blankValueDash }}</div>
      </div>

      <div class="col" *ngIf="patient.hasSsn">
        <strong>SSN:</strong>
        <div>
          <app-sensitive-string
            (valueAccessed)="onSsnAccessed(patient.id)"
            [value]="patient.decryptedSsn"
            [hiddenTextLength]="12" />
        </div>
      </div>
    </div>

    <div class="row mt-3" *ngIf="patient.alternateNames.length > 0">
      <div class="col">
        <strong>OTHER NAME(S)</strong>
        <div>
          <div *ngFor="let alternateName of patient.alternateNames">{{ alternateName }}</div>
        </div>
      </div>
    </div>

    <div class="row mt-3" *ngIf="patient.insuranceIdentifiers.length > 0">
      <div class="col">
        <strong>INSURANCE MEMBER ID:</strong>
        <div>
          <div *ngFor="let insuranceIdentifier of patient.insuranceIdentifiers">
            {{ insuranceIdentifier }}
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col" *ngIf="patient.phoneNumbers.length > 0">
        <strong>PHONE NUMBER:</strong>
        <div>
          <div *ngFor="let phoneNumber of patient.phoneNumbers">
            <app-phone-number [number]="phoneNumber" />
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col" *ngIf="patient.addresses.length > 0">
        <strong>ADDRESS:</strong>
        <div>
          <div *ngFor="let address of patient.addresses" class="mb-2">
            <div>{{ address.line1 }}</div>
            <div>{{ address.line2 }}</div>
            <div>{{ address.city }}, {{ address.state }} {{ address.zip }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col" *ngIf="patient.otherEhrMrns.length > 0">
        <strong>OTHER EHR MRN(S):</strong>
        <div>
          <div *ngFor="let otherEhrMrn of patient.otherEhrMrns" class="mb-2">
            <div>{{ otherEhrMrn.ehr}}:{{ otherEhrMrn.mrn }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
