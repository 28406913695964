import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientNetSavingsTrendBySources } from 'app/core/models/client-net-savings-trend-by-sources.model';


@Component({
  selector: 'app-client-net-savings-trend-by-source-graph-modal',
  templateUrl: './client-net-savings-trend-by-source-graph-modal.component.html',
  styleUrls: ['./client-net-savings-trend-by-source-graph-modal.component.scss'],
})
export class ClientNetSavingsTrendBySourceGraphModalComponent  {
  @Input() clientNetSavingsTrendBySources: ClientNetSavingsTrendBySources;

  constructor(private activeModal: NgbActiveModal) {}

  closeModal($event) {
    $event.preventDefault();
    this.activeModal.close();
  }
}