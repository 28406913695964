<div class="mt-4">
  <h4 class="program-metrics mb-0">Program Metrics</h4>
  <div
    class="mb-4 program-metrics-last-updated-at"
    *ngIf="client.programMetricsLastUpdatedAt">
    Last Updated: {{ client.programMetricsLastUpdatedAt | date: 'MM/dd/yyyy' }}
  </div>

  <ng-container *ngIf="!loadingProgramMetrics; else: spinner">
    <div class="row mt-2">
      <div class="col-3">
        <div class="card net-savings rounded-0 mb-4">
          <div class="card-header">
            <div class="row g-0">
              <div class="col-11">
                <div class="card-title">Net Savings</div>
                <div class="card-subtitle">ALL TIME</div>
              </div>
              <div class="col-1 pt-2">
                <mat-icon class="material-icons-outlined">savings</mat-icon>
              </div>
            </div>
          </div>
          <div class="card-body text-center fs-2">
            <ng-container *ngIf="clientProgramMetrics.netSavings">
              ${{ clientProgramMetrics.netSavings | number }}
            </ng-container>
            <ng-container *ngIf="!clientProgramMetrics.netSavings">
              --
            </ng-container>
          </div>
          <div class="card-footer"></div>
        </div>
      </div>
      <div class="col-3">
        <div class="card verified-captures rounded-0 mb-4">
          <div class="card-header">
            <div class="row g-0">
              <div class="col-11">
                <div class="card-title">Verified Captures</div>
                <div class="card-subtitle">ALL TIME</div>
              </div>
              <div class="col-1 pt-2">
                <mat-icon class="material-icons-outlined">task</mat-icon>
              </div>
            </div>
          </div>
          <div class="card-body text-center fs-2">
            {{ clientProgramMetrics.verifiedCaptures | number | blankValueDash }}
          </div>
          <div class="card-footer"></div>
        </div>
      </div>
      <div class="col-3">
        <div class="card task-opportunities rounded-0">
          <div class="card-header">
            <div class="row g-0">
              <div class="col-11">
                <div class="card-title lh-1">Outstanding Task Opportunities</div>
              </div>
              <div class="col-1 pt-2">
                <mat-icon class="material-icons-outlined">attach_money</mat-icon>
              </div>
            </div>
          </div>
          <div class="card-body text-center fs-2">
            <ng-container *ngIf="clientProgramMetrics.outstandingTaskOpportunities">
              ${{ clientProgramMetrics.outstandingTaskOpportunities | number }}*
            </ng-container>
            <ng-container *ngIf="!clientProgramMetrics.outstandingTaskOpportunities">
              --
            </ng-container>
          </div>
          <div class="card-footer">*ESTIMATED</div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <app-client-net-savings-trend-by-source [client]="client" />
      </div>
      <div class="col-6">
        <div class="border border-dark border-2 rounded-2 pt-3 h-100">
          <h5 class="fs-4 text-black text-center mb-5">Data Feeds Overview</h5>
          <table class="table table-borderless table-striped fs-5">
            <thead class="table-light">
              <tr class="table-dark">
                <td class="text-center">Data Type</td>
                <td class="text-center">Total Records Created</td>
              </tr>
            </thead>
            <tbody>
              <tr *ngIf="clientProgramMetrics.patients > 0">
                <td class="text-center py-3">Patients</td>
                <td class="text-center py-3 fw-bolder">{{ clientProgramMetrics.patients | number | blankValueDash }}</td>
              </tr>
              <tr *ngIf="clientProgramMetrics.encounters > 0">
                <td class="text-center py-3">Encounters</td>
                <td class="text-center py-3 fw-bolder">{{ clientProgramMetrics.encounters | number | blankValueDash }}</td>
              </tr>
              <tr *ngIf="clientProgramMetrics.referrals > 0">
                <td class="text-center py-3">Referrals</td>
                <td class="text-center py-3 fw-bolder">{{ clientProgramMetrics.referrals | number | blankValueDash }}</td>
              </tr>
              <tr *ngIf="clientProgramMetrics.clientPrescriptions > 0">
                <td class="text-center py-3">Prescriptions (eRx)</td>
                <td class="text-center py-3 fw-bolder">{{ clientProgramMetrics.clientPrescriptions | number | blankValueDash }}</td>
              </tr>
              <tr *ngIf="clientProgramMetrics.patientInteractionLocations > 0">
                <td class="text-center py-3">Locations</td>
                <td class="text-center py-3 fw-bolder">{{ clientProgramMetrics.patientInteractionLocations | number | blankValueDash }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<ng-template #spinner> <mat-spinner /> </ng-template>
