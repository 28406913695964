import { Component, Input, Output, EventEmitter } from '@angular/core';
import { SimilarInboundFaxState } from 'app/capture-admin/pages/capture-admin-inbound-fax/capture-admin-inbound-fax.component';

@Component({
  selector: 'app-inbound-fax-duplicate-check',
  templateUrl: './inbound-fax-duplicate-check.component.html',
  styleUrls: ['./inbound-fax-duplicate-check.component.scss'],
})
export class InboundFaxDuplicateCheckComponent {

  @Input() similarInboundFaxState = new SimilarInboundFaxState();
  @Output() reviewDocumentsClicked = new EventEmitter<void>;

  onReviewDuplicatesClick() {
    this.reviewDocumentsClicked.emit();
  }

  get shouldHighlight() {
    return this.similarInboundFaxState.similarInboundFaxes.length > 0 &&
      !this.similarInboundFaxState.warningDismissed;
  }
}
