import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { InboundFax } from '../../../core/models/inbound-fax.model';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-inbound-fax-duplicate-prompt',
  templateUrl: './inbound-fax-duplicate-prompt.component.html',
  styleUrls: ['./inbound-fax-duplicate-prompt.component.scss'],
})
export class InboundFaxDuplicatePromptComponent implements OnInit {

  @Input() inboundFax: InboundFax;
  @Output() submitted = new EventEmitter<{ duplicate: boolean }>();

  formGroup: FormGroup;
  submitEnabled = false;

  constructor(
    private formBuilder: FormBuilder
  ) { }

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      duplicate: []
    });

    this.formGroup.valueChanges.subscribe(value => {
      this.submitEnabled = value !== null;
    });
  }

  onSubmitClick() {
    this.submitted.emit({ duplicate: this.formGroup.value.duplicate });
  }
}
