<app-import-nav />

<h5 class="mt-3">Upload Referral Prescriber Specialty Mapping</h5>
<form novalidate>
  <div class="mb-3 col-12">
    <div class="alert alert-danger" *ngIf="hasError">
      There was a problem uploading the file.
      <ul class="mt-2">
        <li *ngFor="let error of errors">{{ error }}</li>
      </ul>
    </div>
    <app-file-upload
      [hidden]="uploading"
      (uploadStart)="onUploadStart()"
      (uploadComplete)="onUploadComplete($event)"
      (uploadError)="onUploadError($event)"
      [fileType]="'.csv'"
    />
    <div class="py-2" [hidden]="!uploading">
      <fa-icon [icon]="['fas', 'circle-notch']" size="sm" [animation]="'spin'" />
      Uploading file...
    </div>
    <div class="mt-3 border p-3 rounded">
      <strong>File Requirements</strong>
      <ol class="mx-3 ps-2">
        <li class="py-2">
          Required fields
          <ul class="ps-2">
            <li><code>referral_specialty</code></li>
            <li><code>prescriber_specialty</code></li>
            <li><code>action</code></li>
          </ul>
        </li>
        <li class="py-2">
          Example file
          <div>
            <small><strong>NOTE:</strong> Extra columns will be ignored</small>
          </div>
          <div class="bg-light p-2 border w-75">
            <strong>
              <code class="csv-header">
                referral_specialty,prescriber_specialties,action
              </code>
            </strong><br />
            <code>"ALLERGY & IMMUNOLOGY REFERRAL","Allergy",add</code><br />
            <code>"Allergy and Immunology","Family",add</code><br />
            <code>"Allergy Follow-up (Peds)","Allergy",add</code><br />
          </div>
        </li>
        <li class="py-2"><code>.csv</code> only</li>
      </ol>
    </div>
  </div>
</form>
