import { AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { isEmpty } from 'lodash-es';
import { VerificationOutcome } from '../../../core/models/verification-outcome.model';
import { CaptureTransition } from '../../../core/enums/capture-transition.enum';
import { CaptureStatusReason } from '../../../core/enums/capture-status-reason.enum';
import { availableTransitions } from '../../../core/enums/capture-status.enum';
import { ValidationKeys } from 'app/core/services/capture-validation.service';
import { PendingHttpRequestsService } from '../../../core/services/pending-http-requests.service';
import { anyVerificationRequestsPending } from '../../../core/lib/verification-utils';
import { CaptureTransitionOption, keyedTransitionOptions } from '../../../core/options/capture-status.opts';
import { CaptureTransitionReasonOption } from '../../../core/options/capture-status-reason.opts';
import { Capture } from '../../../core/models/capture.model';
import { handleSimpleChanges } from '../../../core/lib/component-utils';
import { PatientPrescriberNote } from 'app/core/models/patient-prescriber-note.model';
import { CaptureService } from 'app/core/services/capture.service';

const confirmTransition = transition => {
  if (transition === CaptureTransition.discard) {
    return confirm('Are you sure you want to discard this capture?');
  } else if (transition === CaptureTransition.reverse) {
    return confirm('Are you sure you want to mark this capture as reversed?');
  } else {
    return true;
  }
};

@Component({
  selector: 'app-verification-outcome-form',
  templateUrl: './verification-outcome-form.component.html',
  styleUrls: ['./verification-outcome-form.component.scss'],
})
export class VerificationOutcomeFormComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @Input() capture: Capture;
  @Input() showValidationSummary: boolean;
  @Input() patientPrescriberNote: PatientPrescriberNote;

  @Output()
  processOutcome = new EventEmitter<{ transition: CaptureTransition; verificationOutcome: VerificationOutcome }>();
  @Output() transitionChange = new EventEmitter<CaptureTransition>();

  formModel: { transition: CaptureTransition; verificationOutcome: VerificationOutcome } = null;
  transitionOptions: CaptureTransitionOption[] = [];
  reasonOptions: CaptureTransitionReasonOption[] = [];
  reasonRequired = false;
  additionalDetailsPlaceholder = '';

  allowSubmit = false;

  verificationRequestPending = false;
  pendingRequestsChangedSubscription: Subscription = null;
  validationKey = ValidationKeys.generalVerification;
  showBlockingReversalExemptionPrompt = false;
  showSpecialtyMismatchExemptionPrompt = false;

  showCeReviewClientNoteInput = false;

  constructor(
    private captureService: CaptureService,
    private pendingHttpRequestsService: PendingHttpRequestsService
  ) {}

  ngOnInit(): void {
    this.captureService.captureChanged.subscribe((capture) => {
      this.initializeForm();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    handleSimpleChanges(changes, (inputName: string) => {
      if (inputName === 'capture') {
        this.initializeForm();
      }
    });
  }

  ngAfterViewInit() {
    this.pendingRequestsChangedSubscription = this.pendingHttpRequestsService.requestsChanged.subscribe(() => {
      this.verificationRequestPending = anyVerificationRequestsPending(this.pendingHttpRequestsService);
    });
  }

  ngOnDestroy() {
    if (this.pendingRequestsChangedSubscription) {
      this.pendingRequestsChangedSubscription.unsubscribe();
    }
  }

  onTransitionChange() {
    this.formModel.verificationOutcome.statusReason = null;
    this.formModel.verificationOutcome.additionalDetails = null;
    this.formModel.verificationOutcome.nonBlockingReversal = null;
    this.formModel.verificationOutcome.blockingReversalExempt = null;
    this.formModel.verificationOutcome.specialtyMismatchExempt = null;
    this.formModel.verificationOutcome.ceReviewNote = null;

    this.transitionChange.emit(this.formModel.transition);
    this.refreshForm(this.formModel.transition);
  }

  onRadioStatusReasonChange(reason) {
    this.allowSubmit = this.determineAllowSubmit();
  }

  onAdditionalDetailsChange() {
    this.allowSubmit = this.determineAllowSubmit();
  }

  onBlockingReversalExemptChange() {
    this.allowSubmit = this.determineAllowSubmit();
  }

  onSpecialtyMismatchExemptChange() {
    this.allowSubmit = this.determineAllowSubmit();
  }

  onSubmit() {
    const { transition } = this.formModel;

    if (confirmTransition(transition)) {
      this.processOutcome.emit(this.formModel);
    }
  }

  get showOutcomeReasons(): boolean {
    return availableTransitions[this.capture.status];
  }

  private initializeForm() {
    const available = availableTransitions[this.capture.status] || [];
    this.transitionOptions = available.map(trn => keyedTransitionOptions[trn]);
    this.refreshForm(null);
  }

  private refreshForm(selectedTransition: CaptureTransition) {
    const selectedTransitionOption = selectedTransition && keyedTransitionOptions[selectedTransition];

    this.reasonOptions = selectedTransitionOption
      ? selectedTransitionOption.reasonOptions.filter(ro => ro.selectable)
      : [];

    this.reasonRequired = this.reasonOptions.length > 0;

    this.formModel = {
      transition: selectedTransition,
      verificationOutcome: { ...this.capture.verificationOutcome },
    };

    // we are clearing out the additional details so, the text area
    // display the placeholder (ie, blank text area)
    this.formModel.verificationOutcome.additionalDetails = null;

    this.additionalDetailsPlaceholder = this.determineAdditionalDetailsPlaceholder();
    this.allowSubmit = this.determineAllowSubmit();

    this.showBlockingReversalExemptionPrompt =
      this.formModel.transition === 'verify' &&
      this.capture.blockingReversalInPool;
    this.showSpecialtyMismatchExemptionPrompt =
      this.formModel.transition === 'verify' &&
      !this.capture.hasMatchingPatientAttachmentSpecialty;

    this.showCeReviewClientNoteInput = this.formModel.transition && this.formModel.transition === 'ce_review';
    if (this.patientPrescriberNote?.active) {
      this.formModel.verificationOutcome.ceReviewNote = this.patientPrescriberNote.content;
    }
  }

  private determineAllowSubmit() {
    return (
      this.formModel.transition &&
      this.validReasons() &&
      this.validAdditionalDetails() &&
      this.validTransitionToVerified() &&
      !this.verificationRequestPending
    );
  }

  private validReasons() {
    return !this.reasonRequired || this.formModel.verificationOutcome.statusReason;
  }

  private validAdditionalDetails() {
    if (this.additionalDetailsRequired) {
      return !isEmpty(this.formModel.verificationOutcome.additionalDetails);
    } else {
      return true;
    }
  }

  public validTransitionToVerified() {
    return this.formModel.transition !== 'verify' ||
      ((!this.capture.blockingReversalInPool ||
        this.formModel.verificationOutcome.blockingReversalExempt) &&
       (this.capture.hasMatchingPatientAttachmentSpecialty ||
        this.formModel.verificationOutcome.specialtyMismatchExempt));
  }

  public get nonBlockingReversalRequired() {
    return (
      this.formModel.transition === CaptureTransition.reverse
    )
  }

  public get additionalDetailsRequired() {
    return (
      (this.formModel.transition === CaptureTransition.gatherEvidenceBlocked &&
        this.formModel.verificationOutcome.statusReason === CaptureStatusReason.other) ||
      this.formModel.transition === CaptureTransition.reverse ||
      this.formModel.transition === CaptureTransition.internalReview
    );
  }

  public get hasAdditionalDetails() {
    return !isEmpty(this.formModel.verificationOutcome.additionalDetails);
  }

  private determineAdditionalDetailsPlaceholder() {
    if (this.formModel.transition === CaptureTransition.gatherEvidenceBlocked) {
      return 'Add additional details.';
    } else {
      return 'Add additional information for Covered Entity.';
    }
  }
}
