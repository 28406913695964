<div class="border py-1 rounded">
  <div class="row mx-0 my-1">
    <div class="col-6">
      <h4 class="heading mb-0">{{ inboundFax.client.humanizedName }}</h4>
      <h5>
        <span class="fax-number">
          <fa-icon [icon]="['fas', 'fax']" size="sm" />
          <ng-container *ngIf="inboundFax.callerNumber; else unknownCaller">
            <app-phone-number [number]="inboundFax.callerNumber" />
            <app-copy-to-clipboard [text]="inboundFax.callerNumber | phoneNumber" />
          </ng-container>
          <ng-template #unknownCaller> UNKNOWN CALLER </ng-template>
        </span>
      </h5>
    </div>

    <div class="col-6">
      <app-inbound-fax-info
        [inboundFax]="inboundFax" />
    </div>
  </div>
</div>
