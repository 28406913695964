import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { FormGroup, FormBuilder, FormGroupDirective } from '@angular/forms';
import { Office } from "app/core/models/office.model";
import { ClientsService } from "app/core/services/clients.service";
import { OfficeService } from "app/core/services/office.service";
import { applyServerErrorsToFormGroup } from 'app/core/lib/form-utils';
import { Client } from "app/core/models/client.model";
import { StateOptions } from 'app/core/options/states.opts';
import { InboundFaxCommunicationMethod } from 'app/core/enums/inbound-fax-communication-method.enum';
import { officeStatusOptions } from 'app/core/options/office-status.opts';
import { HttpErrorResponse } from "@angular/common/http";

@Component({
  selector: 'app-office-header-edit',
  templateUrl: './office-header-edit.component.html',
  styleUrls: ['./office-header-edit.component.scss'],
})

export class OfficeHeaderEditComponent implements OnInit {
  @Input() office: Office;
  @Output() canceled = new EventEmitter();
  @Output() updated = new EventEmitter();

  formGroup: FormGroup;
  clients: Client[];
  saving: boolean;
  stateOptions = StateOptions;
  communicationMethods = Object.values(InboundFaxCommunicationMethod);
  officeStatusOptions = officeStatusOptions;

  @ViewChild('form') public form: FormGroupDirective;

  constructor(
    private formBuilder: FormBuilder,
    private officeService: OfficeService,
    private clientsService: ClientsService,
  ) { }

  ngOnInit() {
    this.saving = false;
    this.initFormGroup();
    this.loadClients();
  }

  onSubmit() {
    this.saving = true;

    this.officeService.update(this.office, this.formGroup.value).subscribe(
      office => this.handleSaveSuccess(office),
      error => this.handleSaveFailure(error)
    );
  }

  onCancelClick() {
    this.canceled.emit();
  }

  private initFormGroup() {
    // NOTE: Not using mapObjectToFormGroup because the
    // array of ids for employedByClientIds causes issue.
    this.formGroup = this.formBuilder.group({
      name: [this.office.name],
      aka: [this.office.aka],
      address1: [this.office.address1],
      address2: [this.office.address2],
      city: [this.office.city],
      state: [this.office.state],
      zip: [this.office.zip],
      phone: [this.office.phone],
      website: [this.office.website],
      communicationMethod: [this.office.communicationMethod],
      status: [this.office.status],
      employedByClientIds: [this.office.employedByClientIds]
    })
  }

  private loadClients() {
    this.clientsService.getList(null, { applicableToReferralClaims: true }).subscribe(result => {
      this.clients = result.clients;
    });
  }

  private handleSaveSuccess(office: Office) {
    this.saving = false;
    this.office = office;
    this.updated.emit();
  }

  private handleSaveFailure(response: HttpErrorResponse) {
    this.saving = false;

    if (response.status === 400) {
      applyServerErrorsToFormGroup(response, this.formGroup);
    } else {
      console.error(response);
    }
  }
}
