import { Component, Input, Output, EventEmitter } from '@angular/core';
import { InboundFax } from '../../../core/models/inbound-fax.model';
import { PdfFileType } from '../../../core/enums/pdf-file-type.enum';
import { SimilarInboundFaxState } from 'app/capture-admin/pages/capture-admin-inbound-fax/capture-admin-inbound-fax.component';

@Component({
  selector: 'app-inbound-fax-main-tab',
  templateUrl: './inbound-fax-main-tab.component.html',
  styleUrls: ['./inbound-fax-main-tab.component.scss'],
})
export class InboundFaxMainTabComponent {
  pdfFileType: PdfFileType = PdfFileType.inboundFax;

  @Input() inboundFax: InboundFax;
  @Input() inboundFaxInitialState: InboundFax;

  @Input() similarInboundFaxState = new SimilarInboundFaxState();

  @Output() inboundFaxAttributeChanged = new EventEmitter<void>;
  @Output() tabChangeRequested = new EventEmitter<string>;
  @Output() nextFaxRequested = new EventEmitter<void>;
  @Output() skipToNextFaxRequested = new EventEmitter<void>;
  @Output() navigateToQueueRequested = new EventEmitter<void>;

  onInboundFaxAttributeChange() {
    this.inboundFaxAttributeChanged.emit();
  }

  onTabChangeRequested(newTab: string) {
    this.tabChangeRequested.emit(newTab);
  }

  onNextFaxRequested() {
    this.nextFaxRequested.emit();
  }

  onSkipToNextFaxRequested() {
    this.skipToNextFaxRequested.emit();
  }

  onNavigateToQueueRequested() {
    this.navigateToQueueRequested.emit();
  }

  onPdfUrlChange(url: string) {
    this.inboundFax.url = url;
  }
}
