export enum CaptureStatusReason {
  additionalReferralDocumentationRequired = 'additional_referral_documentation_required',
  referralCollectionDeferred = 'referral_collection_deferred',
  consultNoteInCeEhr = 'consult_note_in_ce_ehr',
  drugNotReferencedOnConsultNote = 'drug_not_referenced_on_consult_note',
  emrConsultNoteReviewRequired = 'emr_consult_note_review_required',
  invalidPatientFirstNameMatch = 'invalid_patient_first_name_match',
  invalidPatientLastNameMatch = 'invalid_patient_last_name_match',
  notCePatient = 'not_ce_patient',
  serviceDateBeforeFirstInteraction = 'service_date_before_first_interaction',
  expired = 'expired',
  missingInfo = 'missing_info',
  prescriberIsCeProvider = 'prescriber_is_ce_provider',
  rxServiceDateOutsideEligibilityWindow = 'rx_service_date_outside_eligibility_window',
  referralNotBeforeDrugWrittenDate = 'referral_not_before_drug_written_date',
  prescriberSpecialtyDoesNotReceiveReferrals = 'prescriber_specialty_does_not_receive_referrals',
  patientNotAtPractice = 'patient_not_at_practice',
  prescriberNotAtPractice = 'prescriber_not_at_practice',
  practiceRequiresPatientRelease = 'practice_requires_patient_release',
  noRecordForDateOfService = 'no_record_for_date_of_service',
  additionalInformationRequested = 'additional_information_requested',
  other = 'other',
  drugValueIsTooLow = 'drug_value_is_too_low',
  requiresReviewDrug = 'requires_review_drug',
  notAValidPrescriberForClient = 'not_a_valid_prescriber_for_client',
  par8oReversal = 'par8o_reversal',
  claimPartnerReversal = 'claim_partner_reversal',
  claimPartnerRejection = 'claim_partner_rejection',
  claimPartnerRetraction = 'claim_partner_retraction',
  claimPartnerIneligible = 'claim_partner_ineligible',
  transmittedNoResponse = 'transmitted_no_response',
  transmittedNoResponseToCustomNoteFax = 'transmitted_no_response_to_custom_note_fax',
  faxFailed = 'fax_failed',
  clientRejectedPatientPrescriber = 'client_rejected_patient_prescriber',
  clientRejectedPrescription = 'client_rejected_prescription',
  clientRejectedPrescriber = 'client_rejected_prescriber',
  clientRejectedPatient = 'client_rejected_patient',
  clientRejectedPrescriberPrescription = 'client_rejected_prescriber_prescription',
  noValidReferral = 'no_valid_referral',
  ineligibleManufacturer = 'ineligible_manufacturer',
  ineligiblePrescriberSpecialty = 'ineligible_prescriber_specialty',
  incorrectAnswersInCeTaskIdentified = 'incorrect_answers_in_ce_task_identified',
  standardFaxOut = 'standard_fax_out',
  faxQueued = 'fax_queued',
  clientRequestedConsultNoteCollection = 'client_requested_consult_note_collection',
  requestOlderConsultNote = 'request_older_consult_note',
  drugNotReferencedRequestOlderConsultNote = 'drug_not_referenced_request_older_consult_note',
  requestMoreRecentConsultNote = 'request_more_recent_consult_note',
  drugNotReferencedRequestMoreRecentConsultNote = 'drug_not_referenced_request_more_recent_consult_note',
  faxReceivedHasAnIssue = 'fax_received_has_an_issue',
  needsPayment = 'needs_payment',
  needsMailingAddress = 'needs_mailing_address',
  ceHasConsultNote = 'ce_has_consult_note',
  drugNotReferenced = 'drug_not_referenced',
  practiceWorkingOnRequest = 'practice_working_on_request',
  patientMarkedInactive = 'patient_marked_inactive',
  questionOnPatientMatch = 'question_on_patient_match',
  questionOnReferralMatch = 'question_on_referral_match',
  questionOnConsultNote = 'question_on_consult_note',
  alreadyRequestedCEConsultNoteReview = 'already_requested_ce_consult_note_review',
  potentialDataFeedIssue = 'potential_data_feed_issue',
  needsManualReview = 'needs_manual_review',
  duplicateClaimVerified = 'duplicate_claim_verified',
  espExpired = 'esp_expired',
  notAValidCePrescriptionInternalConsultant = 'not_a_valid_ce_prescription_internal_consultant',
  psychologyProviderDrug = 'psychology_provider_drug',
  roiNotObtained = 'roi_not_obtained',
  providerNotValid = 'provider_not_valid',
  duplicateConsultNoteReceived = 'duplicate_consult_note_received',
  noValidEncounters = 'no_valid_encounters'
}
