<div class="card">
  <div class="card-body">
    <div class="row mt-3">
      <div class="col-4">
        <strong>PATIENT</strong>
        <app-patient-name [patient]="inboundFax.patient" />
      </div>
      <div class="col-4">
        <strong>PRESCRIBER</strong>
        <app-prescriber-name [provider]="inboundFax.provider" [linkToProvider]="true" />
      </div>
      <div class="col-4">
        <strong>OUTBOUND FAX</strong>
        <div *ngIf="!inboundFax.outboundFax"><app-blank-value-dash /></div>
        <div *ngIf="inboundFax.outboundFax" class="text-nowrap">
          <span> {{ inboundFax.outboundFax.createdAt | date: 'MM/dd/yyyy' }} </span>
          <div>
            <small>
              <strong>
                <app-phone-number [number]="inboundFax.outboundFax.fax" />
              </strong>
              <div *ngIf="inboundFax.outboundFax.status === outboundFaxStatuses.failed" class="text-danger">Fax Failed</div>
            </small>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-3" *ngIf="inboundFax.communicationMethod">
      <strong>COMMUNICATION METHOD</strong>
      <div>
        {{ inboundFax.communicationMethod }}
      </div>
    </div>
    <div class="mt-3">
      <div *ngIf="inboundFax.statusReason">
        <strong>REASON</strong>
        <div>
          {{ keyedReasonOptions[inboundFax.statusReason].display }}
        </div>
      </div>
    </div>
    <div class="mt-3">
      <div *ngIf="inboundFax.duplicateInboundFax">
        <strong>DUPLICATE FAX</strong>
        <div>
          <a
            target="_blank"
            routerLink="/capture-admin/communications/inbound-faxes/{{ inboundFax.duplicateInboundFax.id }}"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }">

            {{ inboundFax.duplicateInboundFax.patientAttachment.displayName }} -
            {{ inboundFax.duplicateInboundFax.patientAttachment.createdAt | date: 'M/d/yyyy' }}
          </a>
        </div>
      </div>
    </div>
    <div class="mt-3" *ngIf="!!inboundFax.notes">
      <strong>ADDITIONAL DETAILS</strong>
      <p class="mt-1">{{ inboundFax.notes }}</p>
    </div>
    <div *ngIf="inboundFax.captureCount > 0">
      <h5>{{ inboundFax.captureCount }} {{ inboundFax.captureCount === 1 ? 'Capture' : 'Captures' }}</h5>
      <app-inbound-fax-captures [inboundFax]="inboundFax" />
    </div>
  </div>
</div>
