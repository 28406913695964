  <div class="wrapper">
    <div>
      <h5>Potential Duplicate Notes For Patient</h5>
    </div>

    <div class="row fax-row header-row">
      <div class="header col-2">
        ID
      </div>
      <div class="header col-8">
        Patient Documents
      </div>
      <div class="header col-2 lcs-ratio">
        % Similar
      </div>
    </div>

    <div class="scrollable">
      <div class="row fax-row current-fax">
        <div class="row-value col-2">
          {{ inboundFax.id }}
        </div>
        <div class="row-value col-8">
          Current Fax
        </div>
        <div class="row-value col-2 lcs-ratio">
          <app-blank-value-dash />
        </div>
      </div>

      <div *ngFor="let similarInboundFax of similarInboundFaxes"
           class="row fax-row"
           [class.selectable]="similarInboundFax.id !== selectedFax.id"
           [class.selected]="similarInboundFax.id === selectedFax.id"
           (click)="onSimilarFaxClick(similarInboundFax)"
           (keypress)="onSimilarFaxKeyPress(similarInboundFax, $event)"
           role="button"
           tabindex="0">
        <div class="row-value col-2">
          {{ similarInboundFax.id }}
        </div>
        <div class="row-value col-8">
          <div>
            {{ similarInboundFax.patientAttachment.displayName }}
          </div>
          <div>
            <span *ngIf="similarInboundFax.patientAttachment.markedAsInvalid">
              INVALID
            </span>
            <span *ngIf="!similarInboundFax.patientAttachment.markedAsInvalid">
              VALID
            </span>
            -
            {{ similarInboundFax.patientAttachment.createdAt | date: 'M/d/yyyy' }}
          </div>
        </div>
        <div class="row-value col-2 lcs-ratio">
          {{ similarInboundFax.lcsRatio }}%
        </div>
      </div>
    </div>
  </div>
