<button *ngIf="usePdfKitViewer && patientAttachment.url"
  type="button"
  class="consult-note-validator btn btn-sm mt-1"
  [ngClass]="{
    'btn-danger': !patientAttachment.markedAsInvalid,
    'btn-success': patientAttachment.markedAsInvalid
  }"
  [disabled]="markAsInvalidDisabled || markingAsInvalid"
  (click)="onMarkAsInvalidClick($event)"
>
  <fa-icon [icon]="['fas', 'pen']" />
  <span [hidden]="markingAsInvalid">
    <span [hidden]="patientAttachment.markedAsInvalid">Mark As INVALID</span>
    <span [hidden]="!patientAttachment.markedAsInvalid">Mark As VALID</span>
  </span>
  <span [hidden]="!markingAsInvalid">
    <fa-icon [icon]="['fas', 'circle-notch']" size="sm" [animation]="'spin'" /> Saving
  </span>
</button>
