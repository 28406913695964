<div class="d-flex flex-row">
  <ng-select
    [items]="patientAttachmentOptions"
    [multiple]="false"
    [(ngModel)]="selectedAttachmentId"
    (change)="onSelectionChange()"
    bindValue="id"
    name="selectedAttachment"
    placeholder="Select File"
    class="documents-select"
    groupBy="markedAsInvalid"
    [clearable]="false"
  >

    <ng-template ng-optgroup-tmp let-item="item">
      <span *ngIf="item.markedAsInvalid">Invalid</span>
      <span *ngIf="!item.markedAsInvalid">Valid</span>
    </ng-template>

    <ng-template ng-label-tmp let-item="item">
      {{ item.patientAttachment | patientAttachmentDisplayName }} -
      {{ item.patientAttachment.createdAt | date: 'M/d/yyyy h:mm a' }}

      <span *ngIf="item.patientAttachment.duplicatePatientAttachment">
        [<span class="duplicate">DUPLICATE</span>]
      </span>
    </ng-template>

    <ng-template ng-option-tmp let-item="item">
      {{ item.patientAttachment | patientAttachmentDisplayName }} -
      {{ item.patientAttachment.createdAt | date: 'M/d/yyyy h:mm a' }}

      <fa-icon *ngIf="item.verified" [icon]="['fas', 'check']" class="check" />

      <span *ngIf="item.patientAttachment.duplicatePatientAttachment">
        [<span class="duplicate">DUPLICATE</span>]
      </span>
    </ng-template>
  </ng-select>

  <span *ngIf="selectedAttachment?.id && selectedAttachment.standardConsultNote" class="align-self-center ms-2">
    <a routerLink="/capture-admin/consult-notes/{{ selectedAttachment.id }}"
      target="_blank"
      title="Open consult note in new tab">
      <fa-icon [icon]="['fas', 'external-link-alt']" />
    </a>
  </span>
</div>
