import { Injectable } from '@angular/core';
import { HashTable } from '../interfaces/hash-table';
import { isNull, isNil } from 'lodash-es';
import { Capture } from '../models/capture.model';
import { DrugMatchVerification } from '../models/drug-match-verification.model';
import { PatientMatchVerification } from '../models/patient-match-verification.model';
import { SpecialistEncounterVerification } from '../models/specialist-encounter-verification.model';
import { CaptureTransition } from '../enums/capture-transition.enum';
import { CaptureStatusReason } from '../enums/capture-status-reason.enum';
import { VerificationOutcome } from '../models/verification-outcome.model';
import { PrescriberMatchVerification } from '../models/prescriber-match-verification.model';
import { PatientPrescriber } from '../models/patient-prescriber.model';
import {
  earliestReferralMatchVerification,
  isSpecialistEncounterDateWithinRange,
  resolveMostRelevantDrugMatchVerificationFromCapture,
  isSelectedStandardReferralMatchVerification,
  isEhrReferralMatchVerification,
  isClientReferralMatchVerification,
  isClientDateReferralMatchVerification,
  isOutreachAttemptEvidenceDateWithinRange,
} from '../lib/verification-utils';
import { PatientAttachment } from '../models/patient-attachment.model';

export enum ValidationKeys {
  verifyingPatientAttachment = 'verifyingPatientAttachment',
  verifyingOutreachAttemptEvidence = 'verifyingOutreachAttemptEvidence',
  prescriberMatchVerification = 'prescriberMatchVerification',
  patientMatchVerification = 'patientMatchVerification',
  specialistEncounterVerification = 'specialistEncounterVerification',
  drugMatchVerification = 'drugMatchVerification',
  generalVerification = 'generalVerification',
  selectedPatientAttachment = 'selectedPatientAttachment',
  referralMatchVerification = 'referralMatchVerification',
  referralDateBeforeRxWrittenDate = 'referralDateBeforeRxWrittenDate',
  clientReferralDateRequired = 'clientReferralDateRequired',
}

const requiredFieldMessage = 'Required field';
const prescriberMatchMessage =
  'The provider selected on the consult note does not match the prescriber for the capture.';
const patientMatchMessage = 'Invalid patient match';
const encounterDateRequiredMessage = 'At least one encounter date is required';
const drugMatchMessage = 'Invalid drug match';
const supportDocumentMessage = 'You must select a supporting document';
const allPatientFieldsMustMatchMessage = "The patient's first, last, and dob must all match";
const clientPrescriberMissingMessage = "There is no matching prescriber on the client's prescriber list";
const clientPrescriberNpiDoesNotMatchClaimMessage =
  "The client prescriber NPI does not match the capture's candidate's NPI";
const validPatientInteractionIsRequiredMessage = 'A valid patient interaction is required';
const associatePatientAttachmentMessage = 'Please associate consult note';
const noPatientAttachmentForDrugNotReferencedOnConsultNoteMessage = 'Consult note is required';
const referralMatchMessage = 'At least one referral match is required';
const referralDateBeforeRxWrittenDateMessage =
  'The clients policies and procedures require that the referral date is before the written date';
const clientReferralDatesRequiredMessage = 'Client referral date is required';
const noPatientAttachmentSpecialtySelected = 'Specialty must be selected on verifying consult note.';

@Injectable()
export class CaptureValidationService {
  private _errors: HashTable<string> = {};

  public validate(
    transition: CaptureTransition,
    capture: Capture,
    patientPrescriber: PatientPrescriber,
    selectedPatientAttachment: PatientAttachment,
    verificationOutcome: VerificationOutcome
  ): boolean {
    this.clearErrors();

    switch (transition) {
      case CaptureTransition.ceReview:
        this.validateCeReview(capture, patientPrescriber, verificationOutcome);
        break;
      case CaptureTransition.verify:
        this.validateVerify(capture, patientPrescriber, selectedPatientAttachment);
        break;
      case CaptureTransition.verifyClientPrescriberList:
        this.validateVerifyClientPrescriberList(capture);
        break;
      case CaptureTransition.reject:
        this.validateReject(capture, verificationOutcome);
        break;
    }

    return !this.hasErrors;
  }

  public validateNeedsReferralMatchApproval(capture: Capture, patientPrescriber: PatientPrescriber) {
    this.clearErrors();

    this.validateReferralMatchVerifications(capture, patientPrescriber, false);

    return !this.hasErrors;
  }

  public validateVerifyingOutreachAttemptEvidenceDate(capture: Capture, outreachAttemptEvidenceDate: Date) {
    this.clearErrors();

    if (!isOutreachAttemptEvidenceDateWithinRange(outreachAttemptEvidenceDate, capture)) {
      const message = `Outreach date must be within ${capture.client.outreachAttemptEvidenceTimeframeInMonths} months of written date`;

      this.setError(ValidationKeys.verifyingOutreachAttemptEvidence, message);
    }

    return !this.hasErrors;
  }

  public clearErrors() {
    this._errors = {};
  }

  public setError(key: string, message: string) {
    this._errors[key] = message;
  }

  public clearVerifyingAttachmentErrors() {
    this.clearError(ValidationKeys.verifyingPatientAttachment);
    this.clearError(ValidationKeys.patientMatchVerification);
    this.clearError(ValidationKeys.specialistEncounterVerification);
    this.clearError(ValidationKeys.drugMatchVerification);
    this.clearError(ValidationKeys.selectedPatientAttachment);
  }

  public clearReferralMatchErrors() {
    this.clearError(ValidationKeys.referralMatchVerification);
    this.clearError(ValidationKeys.clientReferralDateRequired);
    this.clearError(ValidationKeys.referralDateBeforeRxWrittenDate);
  }

  public clearError(key: string) {
    delete this._errors[key];
  }

  public get hasErrors(): boolean {
    return Object.keys(this._errors).length > 0;
  }

  public getError(key: string): string {
    return this._errors[key];
  }

  // transitions (outcomes)
  private validateCeReview(
    capture: Capture,
    patientPrescriber: PatientPrescriber,
    verificationOutcome: VerificationOutcome
  ) {
    if (verificationOutcome.statusReason === CaptureStatusReason.emrConsultNoteReviewRequired) {
      this.validateReferralMatchVerifications(capture, patientPrescriber);
    }
  }

  private validateVerify(
    capture: Capture,
    patientPrescriber: PatientPrescriber,
    selectedPatientAttachment: PatientAttachment
  ) {
    if (!isNull(capture.verifyingPatientAttachmentId)) {
      if (selectedPatientAttachment && selectedPatientAttachment.id === capture.verifyingPatientAttachmentId) {
        this.validateSelectedSpecialty(selectedPatientAttachment.selectedSpecialty, capture);
        this.validatePrescriberMatchVerification(selectedPatientAttachment.prescriberMatchVerifications, capture);
        this.validatePatientMatchVerification(selectedPatientAttachment.patientMatchVerification);
        this.validateEncounterDateVerification(selectedPatientAttachment.specialistEncounterVerifications, capture);
        this.validateDrugMatchVerification(selectedPatientAttachment, capture);
      } else {
        this.setError(ValidationKeys.selectedPatientAttachment, associatePatientAttachmentMessage);
      }
    } else {
      this.setError(ValidationKeys.verifyingPatientAttachment, supportDocumentMessage);
    }

    this.validateReferralMatchVerifications(capture, patientPrescriber);
  }

  private validateVerifyClientPrescriberList(capture: Capture) {
    if (!isNull(capture.autoVerifyPrescriber) && !isNull(capture.autoVerifyPrescriber.npi)) {
      if (capture.candidate.prescriberNpi !== capture.autoVerifyPrescriber.npi) {
        this.setError(ValidationKeys.generalVerification, clientPrescriberNpiDoesNotMatchClaimMessage);
      }
    } else {
      this.setError(ValidationKeys.generalVerification, clientPrescriberMissingMessage);
    }
    if (
      capture.candidate.patientLast.toLowerCase() !== capture.patient.lastName.toLowerCase() ||
      capture.candidate.patientDob !== capture.patient.dob
    ) {
      this.setError(ValidationKeys.generalVerification, allPatientFieldsMustMatchMessage);
    }
    this.validatePatientInteraction(capture);
  }

  private validateReject(capture: Capture, verificationOutcome: VerificationOutcome) {
    if (verificationOutcome.statusReason === CaptureStatusReason.drugNotReferencedOnConsultNote &&
        isNull(capture.verifyingPatientAttachmentId)) {
      this.setError(ValidationKeys.generalVerification, noPatientAttachmentForDrugNotReferencedOnConsultNoteMessage);
    }
  }

  // verifications (questions)
  private validateSelectedSpecialty(selectedSpecialty: string, _capture: Capture) {
    if (!selectedSpecialty) {
      this.setError(ValidationKeys.generalVerification, noPatientAttachmentSpecialtySelected);
    }
  }

  private validatePrescriberMatchVerification(verifications: PrescriberMatchVerification[], capture: Capture) {
    if (!verifications.some(v => v.matches)) {
      this.setError(ValidationKeys.prescriberMatchVerification, requiredFieldMessage);
    } else if (-1 === verifications.findIndex(v => v.npi === capture.prescriber.npi)) {
      this.setError(ValidationKeys.prescriberMatchVerification, prescriberMatchMessage);
    }
  }

  private validatePatientMatchVerification(verification: PatientMatchVerification) {
    if (isNull(verification) || isNull(verification.matches)) {
      this.setError(ValidationKeys.patientMatchVerification, requiredFieldMessage);
    } else if (verification.matches === false) {
      this.setError(ValidationKeys.patientMatchVerification, patientMatchMessage);
    }
  }

  private validateEncounterDateVerification(verifications: SpecialistEncounterVerification[], capture: Capture) {
    if (verifications.every(v => isNull(v) || isNull(v.encounterDate))) {
      this.setError(ValidationKeys.specialistEncounterVerification, encounterDateRequiredMessage);
    } else if (
      !verifications.some(v => v && isSpecialistEncounterDateWithinRange(v, capture))
    ) {
      const encounterDateRangeMessage = `Must be within ${capture.client.specialistEncounterTimeframeInMonths} months of written date`;
      this.setError(ValidationKeys.specialistEncounterVerification, encounterDateRangeMessage);
    }
  }

  private validateDrugMatchVerification(selectedPatientAttachment: PatientAttachment, capture: Capture) {
    const verification: DrugMatchVerification = resolveMostRelevantDrugMatchVerificationFromCapture(
      capture,
      selectedPatientAttachment
    );

    const drugMatchIsRequired = !selectedPatientAttachment.drugMatchNotRequired;

    if (isNil(verification) || isNil(verification.matches)) {
      this.setError(ValidationKeys.drugMatchVerification, requiredFieldMessage);
    } else if (drugMatchIsRequired && verification.matches === false) {
      this.setError(ValidationKeys.drugMatchVerification, drugMatchMessage);
    }
  }

  private validatePatientInteraction(capture: Capture) {
    const verifyingPatientInteraction = !!capture.verifyingEncounter || !!capture.verifyingReferral;
    const existingOrUseablePatientInteraction =
      verifyingPatientInteraction || !!capture.firstValidVerifyingEncounter;
    if (!existingOrUseablePatientInteraction) {
      this.setError(ValidationKeys.generalVerification, validPatientInteractionIsRequiredMessage);
    }
  }

  private validateReferralMatchVerifications(
    capture: Capture,
    patientPrescriber: PatientPrescriber,
    enforceWrittenDateCheck = true
  ) {
    if (capture.allowEncounterOnlyMatches) {
      return;
    }

    if (capture.referralNotRequiredToVerifyCurrently) {
      return;
    }

    const rmvs = patientPrescriber.referralMatchVerifications;

    const standardRmvs = rmvs.filter(isSelectedStandardReferralMatchVerification);
    const clientReferralMatchVerifications = rmvs.filter(isClientReferralMatchVerification);
    const clientDateReferralMatchVerifications = rmvs.filter(isClientDateReferralMatchVerification);
    const ehrReferralMatchVerifications = rmvs.filter(isEhrReferralMatchVerification);

    const anyReferralMatchVerifications =
      standardRmvs.length > 0 ||
      clientReferralMatchVerifications.length > 0 ||
      clientDateReferralMatchVerifications.length > 0 ||
      ehrReferralMatchVerifications.length > 0

    if (anyReferralMatchVerifications) {
      if (clientDateReferralMatchVerifications.some(rmv => isNull(rmv.clientReferralDate))) {
        this.setError(ValidationKeys.clientReferralDateRequired, clientReferralDatesRequiredMessage);
        return;
      }

      if (patientPrescriber.clientRequiresReferralDateBeforeRxWrittenDate && enforceWrittenDateCheck) {
        const earliestRmv = earliestReferralMatchVerification(rmvs);

        if (earliestRmv.date > capture.candidate.writtenDate) {
          this.setError(ValidationKeys.referralDateBeforeRxWrittenDate, referralDateBeforeRxWrittenDateMessage);
        }
      }
    } else {
      this.setError(ValidationKeys.referralMatchVerification, referralMatchMessage);
    }
  }
}
