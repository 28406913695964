import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgSelectModule } from '@ng-select/ng-select';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { faPlusSquare } from '@fortawesome/free-solid-svg-icons';
import { faMinusSquare } from '@fortawesome/free-solid-svg-icons';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { faRedo } from '@fortawesome/free-solid-svg-icons';
import { faHourglassHalf } from '@fortawesome/free-solid-svg-icons';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { faList } from '@fortawesome/free-solid-svg-icons';
import { faFileAlt } from '@fortawesome/free-solid-svg-icons';
import { faHospital } from '@fortawesome/free-solid-svg-icons';
import { faFax } from '@fortawesome/free-solid-svg-icons';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import { faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons';
import { faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { faFileMedical } from '@fortawesome/free-solid-svg-icons';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { faUndo } from '@fortawesome/free-solid-svg-icons';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { faStopCircle } from '@fortawesome/free-solid-svg-icons';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { faFileSignature } from '@fortawesome/free-solid-svg-icons';
import { faServer } from '@fortawesome/free-solid-svg-icons';
import { FeatureFlagComponent } from './components/feature-flag/feature-flag.component';
import { CaptureStatusPipe } from 'app/shared/pipes/capture-status.pipe';
import { FileUploadComponent } from 'app/shared/components/file-upload/file-upload.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { ExpirationDateComponent } from './components/expiration-date/expiration-date.component';
import { ScrollSpyDirective } from './directives/scroll-spy.directive';
import { GenderPipe } from './pipes/gender.pipe';
import { TaskEstimatedValueComponent } from './components/task-estimated-value/task-estimated-value.component';
import { FaxStatusPipe } from './pipes/fax-status.pipe';
import { BreakAtNpiPipe } from './pipes/break-at-npi.pipe';
import { TaskStatusPipe } from './pipes/task-status.pipe';
import { JobStatusPipe } from './pipes/job-status.pipe';
import { TruncatePipe } from './pipes/truncate.pipe';
import { PaginationComponent } from './components/pagination/pagination.component';
import { PaginationHeaderComponent } from './components/pagination-header/pagination-header.component';
import { BadgeComponent } from './components/badge/badge.component';
import { PhoneNumberPipe } from './pipes/phone-number.pipe';
import { PdfBasicViewerComponent } from './components/pdf-basic-viewer/pdf-basic-viewer.component';
import { PdfFileViewerComponent } from './components/pdf-file-viewer/pdf-file-viewer.component';
import { PdfkitFileViewerComponent } from './components/pdfkit-file-viewer/pdfkit-file-viewer.component';
import { SortableColumnComponent } from './components/sortable-column/sortable-column.component';
import { CandidateReferenceNumberComponent } from './components/candidate-reference-number/candidate-reference-number.component';
import { UserTypeComponent } from './components/user-type/user-type.component';
import { ReplacePipe } from './pipes/replace.pipe';
import { FatFooterComponent } from './components/fat-footer/fat-footer.component';
import { UserStatusComponent } from './components/user-status/user-status.component';
import { UserStatusPipe } from './pipes/user-status.pipe';
import { UserTypePipe } from './pipes/user-type.pipe';
import { HistoryItemUserNamePipe } from './pipes/history-item-user-name.pipe';
import { MonthPickerComponent } from './components/month-picker/month-picker.component';
import { HomeComponent } from './pages/home/home.component';
import { CaptureStatusReasonPipe } from './pipes/capture-status-reason.pipe';
import { CaptureUserAuditOutcomeStatusPipe } from './pipes/capture-user-audit-outcome-status.pipe';
import { CaptureUserAuditOutcomeStatusReasonsPipe } from './pipes/capture-user-audit-outcome-status-reasons.pipe';
import { FormControlServerErrorsComponent } from './components/form-control-server-errors/form-control-server-errors.component';
import { PluckPipe } from './pipes/pluck.pipe';
import { ExcludeBlanksPipe } from './pipes/exclude-blanks.pipe';
import { ProviderOfficeFaxStatusPipe } from './pipes/provider-office-fax-status.pipe';
import { ProviderNameWithNpiPipe } from './pipes/provider-name-with-npi.pipe';
import { AutoFocusDirective } from './directives/auto-focus.directive';
import { AutoSelectAllDirective } from './directives/auto-select-all.directive';
import { ExcludeInputPatternDirective } from './directives/exclude-input-pattern.directive';
import { HighValueIndicatorComponent } from './components/high-value-indicator/high-value-indicator.component';
import { ClaimSourcePipe } from './pipes/claim-source.pipe';
import { ConfirmMfaPhoneModalComponent } from './components/confirm-mfa-phone-modal/confirm-mfa-phone-modal.component';
import { PhoneNumberValidatorDirective } from './directives/phone-validator.directive';
import { JoinListPipe } from './pipes/join-list.pipe';
import { CapitalizePipe } from './pipes/capitalize.pipe';
import { ResizedDirective } from './directives/resized.directive';
import { PatientAttachmentStatusPipe } from 'app/shared/pipes/patient-attachment-status.pipe';
import { PatientAttachmentListingDisplayNamePipe } from 'app/shared/pipes/patient-attachment-listing-display-name.pipe';
import { PatientNameComponent } from './components/patient-name/patient-name.component';
import { PrescriberNameComponent } from './components/prescriber-name/prescriber-name.component';
import { PrescriptionListRowComponent } from './components/prescription-list-row/prescription-list-row.component';
import { PrescriptionCapturesComponent } from './components/prescription-captures/prescription-captures.component';
import { PrescriptionListComponent } from './components/prescription-list/prescription-list.component';
import { PrescriptionListRowDetailComponent } from './components/prescription-list-row-detail/prescription-list-row-detail.component';
import { ImportNavComponent } from './components/import-nav/import-nav.component';
import { ReportAbstractOptionComponent } from './components/report-abstract-option/report-abstract-option.component';
import { ReportClientOptionComponent } from './components/report-client-option/report-client-option.component';
import { ReportAppliedClientOptionComponent } from './components/report-applied-client-option/report-applied-client-option.component';
import { ReportSourcesOptionComponent } from './components/report-sources-option/report-sources-option.component';
import { ReportAppliedSourcesOptionComponent } from './components/report-applied-sources-option/report-applied-sources-option.component';
import { ReportCaptureStatusOptionComponent } from './components/report-capture-status-option/report-capture-status-option.component';
import { ReportAppliedCaptureStatusOptionComponent } from './components/report-applied-capture-status-option/report-applied-capture-status-option.component';
import { ReportBooleanOptionComponent } from './components/report-boolean-option/report-boolean-option.component';
import { ReportAppliedBooleanOptionComponent } from './components/report-applied-boolean-option/report-applied-boolean-option.component';
import { ReportTextOptionComponent } from './components/report-text-option/report-text-option.component';
import { ReportAppliedTextOptionComponent } from './components/report-applied-text-option/report-applied-text-option.component';
import { ReportMonthRangeOptionComponent } from './components/report-month-range-option/report-month-range-option.component';
import { ReportAppliedMonthRangeOptionComponent } from './components/report-applied-month-range-option/report-applied-month-range-option.component';
import { ReportDateRangeOptionComponent } from './components/report-date-range-option/report-date-range-option.component';
import { ReportAppliedDateRangeOptionComponent } from './components/report-applied-date-range-option/report-applied-date-range-option.component';
import { ReportLocationEligibilityStatusOptionComponent } from './components/report-location-eligibility-status-option/report-location-eligibility-status-option.component';
import { ReportAppliedLocationEligibilityStatusOptionComponent } from './components/report-applied-location-eligibility-status-option/report-applied-location-eligibility-status-option.component';
import { ReportClientEntityTypeOptionComponent } from './components/report-client-entity-type-option/report-client-entity-type-option.component';
import { ReportAppliedClientEntityTypeOptionComponent } from './components/report-applied-client-entity-type-option/report-applied-client-entity-type-option.component';
import { ReportClientLeadOptionComponent } from './components/report-client-lead-option/report-client-lead-option.component';
import { ReportAppliedClientLeadOptionComponent } from './components/report-applied-client-lead-option/report-applied-client-lead-option.component';
import { NewReportOptionsTemplateDirective } from './directives/new-report-options-template.directive';
import { ReportDetailsTemplateDirective } from './directives/report-details-template.directive';
import { ResizableTableColumnDirective } from './directives/resizable-table-column.directive';
import { ReportsComponent } from './components/reports/reports.component';
import { UserPresenceComponent } from './components/user-presence/user-presence.component';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { ToastComponent } from './components/toast/toast.component';
import { ClientReferralTaskOptionReasonComponent } from './components/client-referral-task-option-reason/client-referral-task-option-reason.component';
import { PrescriberIncorrectInformationPromptComponent } from './components/prescriber-incorrect-information-prompt/prescriber-incorrect-information-prompt.component';
import { PrescriberOfficesComponent } from './components/prescriber-offices/prescriber-offices.component';
import { PrescriberOfficesScrollableComponent } from './components/prescriber-offices-scrollable/prescriber-offices-scrollable.component';
import { PrescriptionNameComponent } from './components/prescription-name/prescription-name.component';
import { ClientTasksFilterComponent } from './components/client-tasks-filter/client-tasks-filter.component';
import { CopyToClipboardComponent } from './components/copy-to-clipboard/copy-to-clipboard.component';
import { OfficeComponent } from './components/office/office.component';
import { OfficeFaxComponent } from './components/office-fax/office-fax.component';
import { PhoneNumberComponent } from './components/phone-number/phone-number.component';
import { ReferralsStandardListingComponent } from './components/referrals-standard-listing/referrals-standard-listing.component';
import { ReferralsClientListingComponent } from './components/referrals-client-listing/referrals-client-listing.component';
import { ReferralsEhrListingComponent } from './components/referrals-ehr-listing/referrals-ehr-listing.component';
import { ClientPrescriptionMatchKindNamePipe } from './pipes/client-prescription-match-kind-name.pipe';
import { ConsultNoteQueueResultsComponent } from './components/consult-note-queue-results/consult-note-queue-results.component';
import { ConsultNoteOfficeComponent } from './components/consult-note-office/consult-note-office.component';
import { ConsultNoteEstimatedValuesComponent } from './components/consult-note-estimated-values/consult-note-estimated-values.component';
import { ConsultNoteQueueAssignUserComponent } from './components/consult-note-queue-assign-user/consult-note-queue-assign-user.component';
import { ConsultNoteAssignUserSelectComponent } from './components/consult-note-assign-user-select/consult-note-assign-user-select.component';
import { OutboundFaxTemplateComponent } from './components/outbound-fax-template/outbound-fax-template.component';
import { SensitiveStringComponent } from './components/sensitive-string/sensitive-string.component';
import { BlankValueDashPipe } from './pipes/blank-value-dash.pipe';
import { BlankValueDashComponent } from './components/blank-value-dash/blank-value-dash.component';
import { ReferralVerificationReasonShortNamesPipe } from './pipes/referral-verification-reason-short-names.pipe';
import { OrderByPipe } from 'app/shared/pipes/order-by.pipe';
import { CaptureStatusAndReasonSelectComponent } from './components/capture-status-and-reason-select/capture-status-and-reason-select.component';
import { CaptureStatusesAndReasonsPipe } from 'app/shared/pipes/capture-statuses-and-reasons.pipe';
import { SimpleExpandableAreaComponent } from './components/simple-expandable-area/simple-expandable-area.component';
import { PatientInteractionLocationListComponent } from './components/patient-interaction-location-list/patient-interaction-location-list.component';
import { PatientInteractionLocationsExplainerComponent } from './components/patient-interaction-locations-explainer/patient-interaction-locations-explainer.component';
import { CountWithMaxPipe } from 'app/shared/pipes/count-with-max.pipe';
import { FormControlValidityClassDirective } from './directives/form-control-validity-class.directive';
import { NotesForModelComponent } from './components/notes-for-model/notes-for-model.component';
import { NotesForModelAddComponent } from './components/notes-for-model-add/notes-for-model-add.component';
import { NoteComponent } from './components/note/note.component';
import { ProviderOfficeAssociationNotesComponent } from './components/provider-office-association-notes/provider-office-association-notes.component';
import { ProviderOfficeAssociationNotesModalComponent } from './components/provider-office-association-notes-modal/provider-office-association-notes-modal.component';
import { PatientOutboundFaxesComponent } from './components/patient-outbound-faxes/patient-outbound-faxes.component';
import { ConsultNoteReceivedComponent } from './components/consult-note-received/consult-note-received.component';
import { InboundCallReceivedComponent } from './components/inbound-call-received/inbound-call-received.component';
import { ClientNameComponent } from './components/client-name/client-name.component';
import { EhrReferralVerificationsFormComponent } from './components/ehr-referral-verification-form/ehr-referral-verification-form.component';
import { PatientAttachmentDisplayNamePipe } from './pipes/patient-attachment-display-name.pipe';
import { ImageViewModalComponent } from './components/image-view-modal/image-view-modal.component';
import { AdditionalDetailsInputComponent } from './components/additional-details-input/additional-details-input.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgbModule,
    NgSelectModule,
    MatProgressSpinnerModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatTooltipModule,
    MatIconModule,
    FontAwesomeModule,
    PdfViewerModule,
    BrowserAnimationsModule,
  ],
  declarations: [
    FeatureFlagComponent,
    CaptureStatusPipe,
    FileUploadComponent,
    NavbarComponent,
    NotFoundComponent,
    ExpirationDateComponent,
    ScrollSpyDirective,
    GenderPipe,
    FaxStatusPipe,
    BreakAtNpiPipe,
    TaskEstimatedValueComponent,
    TaskStatusPipe,
    JobStatusPipe,
    TruncatePipe,
    PaginationComponent,
    PaginationHeaderComponent,
    BadgeComponent,
    PhoneNumberPipe,
    PdfBasicViewerComponent,
    PdfFileViewerComponent,
    PdfkitFileViewerComponent,
    SortableColumnComponent,
    CandidateReferenceNumberComponent,
    UserTypeComponent,
    ReplacePipe,
    FatFooterComponent,
    UserStatusComponent,
    UserStatusPipe,
    UserTypePipe,
    MonthPickerComponent,
    HomeComponent,
    CaptureStatusReasonPipe,
    CaptureUserAuditOutcomeStatusPipe,
    CaptureUserAuditOutcomeStatusReasonsPipe,
    FormControlServerErrorsComponent,
    PluckPipe,
    ExcludeBlanksPipe,
    ProviderOfficeFaxStatusPipe,
    ProviderNameWithNpiPipe,
    ClaimSourcePipe,
    AutoFocusDirective,
    AutoSelectAllDirective,
    ExcludeInputPatternDirective,
    HighValueIndicatorComponent,
    ConfirmMfaPhoneModalComponent,
    PhoneNumberValidatorDirective,
    JoinListPipe,
    CapitalizePipe,
    ResizedDirective,
    PatientAttachmentStatusPipe,
    PatientAttachmentListingDisplayNamePipe,
    PatientNameComponent,
    PrescriberNameComponent,
    PrescriptionCapturesComponent,
    PrescriptionListComponent,
    PrescriptionListRowComponent,
    PrescriptionListRowDetailComponent,
    ImportNavComponent,
    ReportAbstractOptionComponent,
    ReportClientOptionComponent,
    ReportAppliedClientOptionComponent,
    ReportSourcesOptionComponent,
    ReportAppliedSourcesOptionComponent,
    ReportCaptureStatusOptionComponent,
    ReportAppliedCaptureStatusOptionComponent,
    ReportBooleanOptionComponent,
    ReportAppliedBooleanOptionComponent,
    ReportTextOptionComponent,
    ReportAppliedTextOptionComponent,
    ReportMonthRangeOptionComponent,
    ReportAppliedMonthRangeOptionComponent,
    ReportDateRangeOptionComponent,
    ReportAppliedDateRangeOptionComponent,
    ReportLocationEligibilityStatusOptionComponent,
    ReportAppliedLocationEligibilityStatusOptionComponent,
    ReportClientEntityTypeOptionComponent,
    ReportAppliedClientEntityTypeOptionComponent,
    ReportClientLeadOptionComponent,
    ReportAppliedClientLeadOptionComponent,
    ReportsComponent,
    NewReportOptionsTemplateDirective,
    ReportDetailsTemplateDirective,
    ResizableTableColumnDirective,
    HistoryItemUserNamePipe,
    UserPresenceComponent,
    SafeHtmlPipe,
    ToastComponent,
    ClientReferralTaskOptionReasonComponent,
    PrescriberIncorrectInformationPromptComponent,
    PrescriberOfficesComponent,
    PrescriberOfficesScrollableComponent,
    PrescriptionNameComponent,
    ClientTasksFilterComponent,
    CopyToClipboardComponent,
    OfficeComponent,
    OfficeFaxComponent,
    OutboundFaxTemplateComponent,
    PhoneNumberComponent,
    ReferralsStandardListingComponent,
    ReferralsClientListingComponent,
    ReferralsEhrListingComponent,
    ClientPrescriptionMatchKindNamePipe,
    ConsultNoteQueueResultsComponent,
    ConsultNoteOfficeComponent,
    ConsultNoteEstimatedValuesComponent,
    ConsultNoteQueueAssignUserComponent,
    ConsultNoteAssignUserSelectComponent,
    SensitiveStringComponent,
    BlankValueDashPipe,
    BlankValueDashComponent,
    ReferralVerificationReasonShortNamesPipe,
    OrderByPipe,
    CaptureStatusAndReasonSelectComponent,
    CaptureStatusesAndReasonsPipe,
    SimpleExpandableAreaComponent,
    PatientInteractionLocationListComponent,
    PatientInteractionLocationsExplainerComponent,
    CountWithMaxPipe,
    FormControlValidityClassDirective,
    NotesForModelComponent,
    NotesForModelAddComponent,
    NoteComponent,
    ProviderOfficeAssociationNotesComponent,
    ProviderOfficeAssociationNotesModalComponent,
    ConsultNoteReceivedComponent,
    InboundCallReceivedComponent,
    PatientOutboundFaxesComponent,
    ClientNameComponent,
    EhrReferralVerificationsFormComponent,
    PatientAttachmentDisplayNamePipe,
    ImageViewModalComponent,
    AdditionalDetailsInputComponent,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    FontAwesomeModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    MatIconModule,
    MatTooltipModule,
    NgbModule,
    NgSelectModule,
    FeatureFlagComponent,
    CaptureStatusPipe,
    FileUploadComponent,
    NavbarComponent,
    NotFoundComponent,
    ExpirationDateComponent,
    ScrollSpyDirective,
    GenderPipe,
    FaxStatusPipe,
    BrowserAnimationsModule,
    BreakAtNpiPipe,
    TaskEstimatedValueComponent,
    TaskStatusPipe,
    TruncatePipe,
    PaginationComponent,
    PaginationHeaderComponent,
    BadgeComponent,
    PhoneNumberPipe,
    PdfBasicViewerComponent,
    PdfFileViewerComponent,
    PdfkitFileViewerComponent,
    SortableColumnComponent,
    CandidateReferenceNumberComponent,
    UserTypeComponent,
    ReplacePipe,
    FatFooterComponent,
    UserStatusComponent,
    MonthPickerComponent,
    CaptureStatusReasonPipe,
    CaptureUserAuditOutcomeStatusPipe,
    CaptureUserAuditOutcomeStatusReasonsPipe,
    FormControlServerErrorsComponent,
    PluckPipe,
    ExcludeBlanksPipe,
    ProviderOfficeFaxStatusPipe,
    ClaimSourcePipe,
    ProviderNameWithNpiPipe,
    AutoFocusDirective,
    AutoSelectAllDirective,
    ExcludeInputPatternDirective,
    HighValueIndicatorComponent,
    PhoneNumberValidatorDirective,
    JoinListPipe,
    CapitalizePipe,
    ResizedDirective,
    PatientAttachmentStatusPipe,
    PatientAttachmentListingDisplayNamePipe,
    PatientNameComponent,
    PrescriberNameComponent,
    PrescriptionCapturesComponent,
    PrescriptionListComponent,
    PrescriptionListRowComponent,
    PrescriptionListRowDetailComponent,
    ImportNavComponent,
    ReportAbstractOptionComponent,
    ReportClientOptionComponent,
    ReportAppliedClientOptionComponent,
    ReportSourcesOptionComponent,
    ReportAppliedSourcesOptionComponent,
    ReportCaptureStatusOptionComponent,
    ReportAppliedCaptureStatusOptionComponent,
    ReportBooleanOptionComponent,
    ReportAppliedBooleanOptionComponent,
    ReportTextOptionComponent,
    ReportAppliedTextOptionComponent,
    ReportMonthRangeOptionComponent,
    ReportAppliedMonthRangeOptionComponent,
    ReportDateRangeOptionComponent,
    ReportAppliedDateRangeOptionComponent,
    ReportLocationEligibilityStatusOptionComponent,
    ReportAppliedLocationEligibilityStatusOptionComponent,
    ReportClientEntityTypeOptionComponent,
    ReportAppliedClientEntityTypeOptionComponent,
    ReportClientLeadOptionComponent,
    ReportAppliedClientLeadOptionComponent,
    ReportsComponent,
    NewReportOptionsTemplateDirective,
    ReportDetailsTemplateDirective,
    ResizableTableColumnDirective,
    HistoryItemUserNamePipe,
    UserPresenceComponent,
    SafeHtmlPipe,
    ToastComponent,
    ClientReferralTaskOptionReasonComponent,
    PrescriberIncorrectInformationPromptComponent,
    PrescriberOfficesComponent,
    PrescriberOfficesScrollableComponent,
    PrescriptionNameComponent,
    ClientTasksFilterComponent,
    CopyToClipboardComponent,
    OfficeComponent,
    OfficeFaxComponent,
    OutboundFaxTemplateComponent,
    PhoneNumberComponent,
    ReferralsStandardListingComponent,
    ReferralsClientListingComponent,
    ReferralsEhrListingComponent,
    ClientPrescriptionMatchKindNamePipe,
    ConsultNoteQueueResultsComponent,
    ConsultNoteOfficeComponent,
    ConsultNoteEstimatedValuesComponent,
    ConsultNoteQueueAssignUserComponent,
    ConsultNoteAssignUserSelectComponent,
    SensitiveStringComponent,
    BlankValueDashPipe,
    BlankValueDashComponent,
    ReferralVerificationReasonShortNamesPipe,
    OrderByPipe,
    CaptureStatusAndReasonSelectComponent,
    CaptureStatusesAndReasonsPipe,
    SimpleExpandableAreaComponent,
    PatientInteractionLocationListComponent,
    PatientInteractionLocationsExplainerComponent,
    CountWithMaxPipe,
    FormControlValidityClassDirective,
    NotesForModelComponent,
    NotesForModelAddComponent,
    NoteComponent,
    ProviderOfficeAssociationNotesComponent,
    ProviderOfficeAssociationNotesModalComponent,
    ConsultNoteReceivedComponent,
    InboundCallReceivedComponent,
    PatientOutboundFaxesComponent,
    ClientNameComponent,
    EhrReferralVerificationsFormComponent,
    PatientAttachmentDisplayNamePipe,
    ImageViewModalComponent,
    AdditionalDetailsInputComponent,
  ],
  providers: [
    provideHttpClient(withInterceptorsFromDi())
  ]
})
export class SharedModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(faPlusSquare);
    library.addIcons(faPlusCircle);
    library.addIcons(faMinusSquare);
    library.addIcons(faCircleNotch);
    library.addIcons(faCheck);
    library.addIcons(faCog);
    library.addIcons(faCalendar);
    library.addIcons(faTimes);
    library.addIcons(faSearch);
    library.addIcons(faList);
    library.addIcons(faFileAlt);
    library.addIcons(faHospital);
    library.addIcons(faFax);
    library.addIcons(faInfoCircle);
    library.addIcons(faCloudUploadAlt);
    library.addIcons(faCloudDownloadAlt);
    library.addIcons(faAngleDoubleRight);
    library.addIcons(faExclamationCircle);
    library.addIcons(faExclamationTriangle);
    library.addIcons(faExternalLinkAlt);
    library.addIcons(faFileMedical);
    library.addIcons(faHourglassHalf);
    library.addIcons(faTimesCircle);
    library.addIcons(faRedo);
    library.addIcons(faCheckCircle);
    library.addIcons(faUndo);
    library.addIcons(faPen);
    library.addIcons(faTrash);
    library.addIcons(faStopCircle);
    library.addIcons(faCopy);
    library.addIcons(faFileSignature);
    library.addIcons(faServer);
  }
}
