import { InboundFaxStatusReason } from '../enums/inbound-fax-status-reason.enum';
import { keyBy } from 'lodash-es';

export class FaxStatusReasonOption {
  constructor(public value: string, public display: string, public selectable: boolean = true) {}
}

const reasonOptions = [
  new FaxStatusReasonOption(InboundFaxStatusReason.patientNotAtPractice, 'Patient not at this practice'),
  new FaxStatusReasonOption(InboundFaxStatusReason.prescriberNotAtPractice, 'Provider not at this practice'),
  new FaxStatusReasonOption(InboundFaxStatusReason.patientReleaseRequired, 'Practice requires patient release'),
  new FaxStatusReasonOption(
    InboundFaxStatusReason.prescriberSpecialtyDoesNotReceiveReferrals,
    'Prescriber specialty does not receive referrals.',
    false
  ),
  new FaxStatusReasonOption(InboundFaxStatusReason.faxReceivedHasAnIssue, 'Fax received has an issue'),
  new FaxStatusReasonOption(InboundFaxStatusReason.officeOptedOut, 'Office opted out'),
  new FaxStatusReasonOption(InboundFaxStatusReason.needsPayment, 'Needs payment'),
  new FaxStatusReasonOption(InboundFaxStatusReason.needsMailingAddress, 'Needs mailing address'),
  new FaxStatusReasonOption(InboundFaxStatusReason.ceHasConsultNote, 'CE has consult note'),
  new FaxStatusReasonOption(InboundFaxStatusReason.practiceWorkingOnRequest, 'Practice is working on request'),
  new FaxStatusReasonOption(InboundFaxStatusReason.noRecordForDateOfService, 'No record for date of service'),
  new FaxStatusReasonOption(InboundFaxStatusReason.additionalInformationRequested, 'Additional information requested'),
  new FaxStatusReasonOption(InboundFaxStatusReason.junkNonMedicalDocument, 'Junk - Non-medical document'),
  new FaxStatusReasonOption(
    InboundFaxStatusReason.duplicate,
    'Duplciate',
    false
  ),
  new FaxStatusReasonOption(InboundFaxStatusReason.other, 'Other'),
];

const keyedReasonOptions = keyBy(reasonOptions, 'value');

export { keyedReasonOptions, reasonOptions };
