import { Pipe, PipeTransform } from '@angular/core';
import { PatientAttachmentListing } from 'app/core/models/patient-attachment-listing.model';

@Pipe({
  name: 'patientAttachmentListingDisplayName',
})
export class PatientAttachmentListingDisplayNamePipe implements PipeTransform {
  transform(document: PatientAttachmentListing): string {
    if (!document) return ' - ';
    const formattedDate = new Date(document.createdAt).toLocaleString('en-US', {
      month: 'numeric',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    });
    return `${document.displayName} - ${formattedDate}`;
  }
}
