<div class="card mt-2" [class.alert-warning]="shouldHighlight">
  <div class="card-body">
    <h4 class="mb-2">Duplicate Check</h4>

    <p class="mt-2 p-2 bg-light" *ngIf="!similarInboundFaxState.fetched && !similarInboundFaxState.fetching">
      Please select a patient, prescriber, and outbound fax.
    </p>

    <div *ngIf="similarInboundFaxState.fetching">
      <div class="row">
        <div class="col-auto">
          <mat-spinner diameter="30" />
        </div>
        <div class="col">
          Checking for duplicates
        </div>
      </div>
    </div>

    <div *ngIf="similarInboundFaxState.fetched && similarInboundFaxState.similarInboundFaxes.length === 0">
      No potential duplicates found.
    </div>

    <div *ngIf="similarInboundFaxState.fetched && similarInboundFaxState.similarInboundFaxes.length > 0">
      <div class="row" *ngIf="!similarInboundFaxState.warningDismissed">
        <div class="col-auto">
          <mat-icon
            class="alert-icon text-warning"
            matTooltip="Possible Duplicate">
            warning
          </mat-icon>
        </div>
        <div class="col">
          <p>
            This document is potentially a duplicate of other existing consult
            note(s) for this patient.
          </p>

          <button class="btn btn-primary" (click)="onReviewDuplicatesClick()">
            Review Documents
          </button>
        </div>
      </div>

      <div *ngIf="similarInboundFaxState.warningDismissed">
        Possible duplicate document warning dismissed.
      </div>
    </div>
  </div>
</div>
